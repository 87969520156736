@import "../../../scss/lib/mixins";

.Wrapper { 
    margin-top: 16px;
    background-color: $white;
    border-radius: 24px;
    position: relative;

    @include media-breakpoint-up(lg) {
        margin-top: 0;
    }
}

.Content {
    padding: 32px;
}

.Header {
    color: $black;
    font-size: 18px;
    line-height: 21px;
}

.USP {
    padding-left: 0;
    padding-top: 24px;
    list-style: none;

    li {
        margin-bottom: 8px;
    }
}


.CircleWrapper {
  width: 22px;
  height: 22px;
  background-color: #439ed5;
  font-family: 'Marujo';
  font-size: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 8px;
  border-radius: 50%;
  color: $white;
  flex-shrink: 0;
  
  &Icon {
    position: relative;

    &:after {
        content: url("../../../assets/images/check.svg");
        position: absolute;
    }
  }
}

.Illustration {
    position: absolute;
    right: 10px;
}

.blobs {
    z-index: -1;
    left: 0;
    top: 0;

    @include media-breakpoint-between(md, md) {
        left: 400px;
        top: -30px;
    }

    @include media-breakpoint-up(lg) {
        left: 30px;
        top: -60px;
    }
}


