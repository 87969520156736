@import "variables";

@font-face {
  font-family: '#{$icomoon-font-family}';
  src:  url('#{$icomoon-font-path}/#{$icomoon-font-family}.eot?vbywos');
  src:  url('#{$icomoon-font-path}/#{$icomoon-font-family}.eot?vbywos#iefix') format('embedded-opentype'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.ttf?vbywos') format('truetype'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.woff?vbywos') format('woff'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.svg?vbywos##{$icomoon-font-family}') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

i {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: '#{$icomoon-font-family}' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-DirectionLeft {
  &:before {
    content: $icon-DirectionLeft; 
  }
}
.icon-DirectionRight {
  &:before {
    content: $icon-DirectionRight; 
  }
}
.icon-play {
  &:before {
    content: $icon-play; 
  }
}
.icon-right {
  &:before {
    content: $icon-right; 
  }
}
.icon-arrow-down {
  &:before {
    content: $icon-arrow-down; 
  }
}
.icon-navicon {
  &:before {
    content: $icon-navicon; 
  }
}
.icon-close {
  &:before {
    content: $icon-close; 
  }
}
.icon-edit {
  &:before {
    content: $icon-edit; 
  }
}
.icon-grabage {
  &:before {
    content: $icon-grabage; 
  }
}
.icon-user {
  &:before {
    content: $icon-user; 
  }
}
.icon-mark {
  &:before {
    content: $icon-mark; 
  }
}
.icon-place-board-left {
  &:before {
    content: $icon-place-board-left; 
  }
}
.icon-school-board {
  &:before {
    content: $icon-school-board; 
  }
}
.icon-place-board {
  &:before {
    content: $icon-place-board; 
  }
}

