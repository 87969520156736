@import "../../scss/lib/mixins";

.Wrapper {
    position: relative;
}

.backPanel {
    background-color: $light-red;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 157px;

    @include media-breakpoint-up(md) {
        height: 365px;
    }
}

.Content {
    position: relative;
    padding-bottom: 10px;
    padding-top: 10px;
    @include media-breakpoint-up(lg) {
        padding-bottom: 100px;
        padding-top: 100px;
    }

    @include media-breakpoint-up(md) {
        padding-bottom: 60px;
        padding-top: 65px;
    }
}

.Text {
    padding-top: 8px;
    font-size: 14px;
    font-weight: 700;
    color: white;
    text-align: end;
}



.BottomIllustration {
    position: absolute;
    height: 70px;
    width: 70px;
    bottom: 7%;
    
    @include media-breakpoint-up(lg) { 
        left: 20%;
        bottom: 4%;
        height: 100px;
        width: 100px;
    }
}

.UpperIllustration{
    position: absolute;
    z-index: 20;
    top: -12%;
    right: -25px;
    height: 70px;
    width: 70px;

    @include media-breakpoint-up(md) { 
        right: -8%;
        top: -0%;
        height: 100px;
        width: 100px;
    }

    @include media-breakpoint-up(lg) { 
        right: -5%;
        top: -0%;
        height: 100px;
        width: 100px;
    }
}

.blob {
    left: -30px;
    top: -50px;
    height: 250px;
    width: 180px;
    left: -49px;
    top: -52px;
    height: 250px;
    width: 180px;
    transform: scale(0.8);

    @include media-breakpoint-up(md) { 
        left: -80px;
        top: 5px;
        height: 450px;
        width: 450px;
        transform: none;
    }
}


.videoPanel {
    border-radius: 16px;
    background-color: $white;
    padding: 12px 12px 32px 12px;
    position: relative;
    margin-bottom: 30px;

    @include media-breakpoint-up(lg) {
        display: flex;
        align-items: center;
        margin-bottom: 0;
        padding: 26px 32px 32px 24px;
    }
}

.smallVideoIllustration {
    position: absolute;
    left: 5%;
    bottom: -60px; 
}