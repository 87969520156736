@import '../../../scss/lib/mixins';

.button-pill {
  display: inline-flex;
  align-items: center;
  background-color: #439ed5;
  padding: 13px 24px;
  border: none;
  color: $white;
  text-align: center;
  text-decoration: none;
  cursor: pointer;
  border-radius: 12px;
  font-size: 16px;
  font-weight: bold;
  line-height: 21px;
  justify-content: center;
  position: relative;
  z-index: 1;

  span {
    margin-top: -1px;
  }

  &:focus {
    outline: none;
  }

  &:hover {
    background-color: darken(#439ed5, 4%);
    color: $white;
  }

  &.button-grey {
    background-color: $gray-600;

    &:hover {
      background-color: darken($gray-600, 4%);
    }
  }

  &.button-red {
    background-color: $red;

    &:hover {
      background-color: darken($red, 4%);
    }
  }
  &.button-dark-red {
    background-color: $dark-red;

    &:hover {
      background-color: darken($dark-red, 4%);
    }
  }

  &.button-white {
    background-color: $white;
    color: $light-pink;

    &:before {
      content: url('../../../assets/images/arrow-red.svg');
      transform: scale(0.9);
    }

    &:hover {
      background-color: darken($white, 4%);

      span {
        color: $light-pink;
      }
    }
  }

  &.button-dark-green {
    background-color: $dark-green;
    color: $white;
  }

  &.button-green {
    background-color: $green;
    color: $white;
  }

  &.button-yellow {
    background-color: $yellow;
    color: $white;

    &:before {
      content: url('../../../assets/images/arrow.svg');
      transform: scale(0.9);
    }

    &:hover {
      background-color: darken($yellow, 4%);

      span {
        color: $white;
      }
    }
  }

  &.button-yellow-cyan {
    background-color: $yellow;
    color: $cyan;

    &:hover {
      background-color: darken($yellow, 4%);
    }
  }

  &.button-yellow-noarrow {
    background-color: $yellow;
    color: $red;

    &:hover {
      background-color: darken($yellow, 4%);
      span {
        color: $red;
      }
    }
  }
}

.btn-wide {
  width: 100%;
  justify-content: start;

  &:disabled {
    color: rgba(255, 255, 255, 0.5);
    &:hover {
      background-color: $blue;
      cursor: not-allowed;
    }
  }
}

.btn-flat {
  height: 32px;
  border-radius: 8px;
}

.arrow-link {
  position: relative;
  padding-left: 56px;

  &:before {
    content: url('../../../assets/images/arrow.svg');
    position: absolute;
    left: 20px;
  }
}
.whatsapp {
  position: relative;
  padding-left: 56px;

  &:before {
    content: url('../../../assets/images/bellen.svg');
    position: absolute;
    left: 20px;
  }
}

.duplicate {
  position: relative;
  padding-left: 56px;

  &:before {
    content: url('../../../assets/images/dupliceren.svg');
    position: absolute;
    left: 20px;
  }
}
.mail {
  position: relative;
  padding-left: 56px;

  &:before {
    content: url('../../../assets/images/mail_norect.svg');
    position: absolute;
    left: 20px;
  }
}

.placeboard-link {
  position: relative;
  padding-right: 56px;

  &:after {
    content: url('../../../assets/images/place-board.svg');
    position: absolute;
    right: 20px;
  }
}

.check {
  position: relative;
  padding-left: 56px;

  &:before {
    content: url('../../../assets/images/rectangular-check.svg');
    position: absolute;
    left: 20px;
  }
}

.eye {
  position: relative;
  padding: 20px;

  &:before {
    content: url('../../../assets/images/eye.svg');
    position: absolute;
  }
  @include media-breakpoint-up(lg) {
    padding: 24px;
  }
}
.eye-off {
  position: relative;
  padding: 2-px;

  &:before {
    content: url('../../../assets/images/eye-off.svg');
    position: absolute;
  }
  @include media-breakpoint-up(lg) {
    padding: 24px;
  }
}

.no-text-arrow,
.no-text-arrow-dark-orange {
  position: relative;
  width: 32px;
  height: 32px;
  padding: 0;
  border-radius: 8px;

  &:before {
    content: url('../../../assets/images/arrow.svg');
    position: absolute;
    left: 7px;
  }
}

.no-text-arrow-dark-orange {
  background-color: $dark-orange;
}

.no-text-arrow-dark-orange:hover {
  background-color: darken($dark-orange, 4);
}

.no-text-placeboard {
  position: relative;
  width: 32px;
  height: 32px;
  padding: 0;

  &:before {
    content: url('../../../assets/images/place-board.svg');
    position: absolute;
    left: 4px;
  }
}

.btn-hover-fill {
  background-color: transparent;
  color: $blue;
  font-size: 16px;
  font-weight: bold;
  position: relative;
  display: flex;
  padding: 0;
  padding-right: 20px;
  transition: 0.2s background-color;

  &:hover {
    background-color: $blue;
  }

  &:before {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 32px;
    height: 32px;
    background-color: $blue;
    border-radius: 12px;
    left: 0;
    margin-right: 14px;
  }
}

.btn-loader {
  display: inline-flex;
  justify-content: center;
  min-height: 46px;
  &:after {
    content: '';
    background: url('../../../assets/images/oval.svg');
    width: 24px;
    height: 24px;
    display: inline-block;
    background-size: cover;
  }
  span {
    display: none;
  }
}
