@import '../../scss/lib/mixins';

.header {
    padding: 60px 0;
    // height: 500px;
    @include media-breakpoint-up(lg) {
        padding: 85px 0;
    }
}

.titleWapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    z-index: 1;
    height: 100%;
    justify-content: center;
}

.title {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;

}

.line {
    background-color: $white;
    color: $dark-orange;
    box-shadow: 0px 15px 10px rgba(0, 0, 0, 0.06);
    transform: rotate(-2deg);
    padding: 16px 16px 8px 16px;
    position: relative;
    z-index: 1;
    text-align: center;
    h3 {
        font-size: 32px;
        line-height: 37px;

        @include media-breakpoint-up(lg) {
            font-size: 56px;
            line-height: 64px;
        }
    }

    & + & {
        top: -10px;
        left: 23px;

        @include media-breakpoint-up(lg) {
            left: 15px;
        }
    }
}

.blob {
    top: -176px;
    left: calc(50% + 43px);
    transform: translateX(-50%);
    position: absolute;
    z-index: 1;
    @include media-breakpoint-up(lg) {
        top: -159px;
        left: calc(50% + 37px);
    }
}

.blobScale {
    position: relative;
    transform: scale(0.6);

    @include media-breakpoint-up(lg) {
        transform: rotate(-2deg);
    }
}

.subtitle {
    z-index: 3;
    background-color: #439ed5;
    color: $white;
    font-family: Marujo;
    font-size: 21px;
    line-height: 24px;
    padding: 8px 24px;
    transform: rotate(-2deg);
    font-size: 16px;
    line-height: 21px;
    position: relative;
    top: 8px;

    @include media-breakpoint-up(lg) {
        font-size: 21px;
        line-height: 24px;
        padding: 8px 90px;
    }
}

.leftIllustration {
    position: absolute;
    left: -20px;
    top: -55px;
    transform: scale(0.8);

    @include media-breakpoint-up(lg) {
        left: 50px;
        top: -60px;
        transform: none;
    }
}

.topRightIllustration {
    position: absolute;
    right: -20px;
    top: -65px;
    transform: scale(0.8);

    @include media-breakpoint-up(lg) {
        right: 30px;
        top: -95px;
        transform: none;
    }
}

.bottomRightIllustration {
    position: absolute;
    right: -5px;
    top: 130px;
    transform: scale(0.8);
    z-index: 2;

    @include media-breakpoint-up(lg) {
        right: 95px;
        top: 190px;
        transform: none;
    }
}

.introText {
    background-color: $dark-red;
    color: $white;
    font-size: 18px;
    line-height: 25px;
    font-weight: 600;
    text-align: center;
    box-shadow: 0px 30px 30px rgba(0, 0, 0, 0.05);
    border-radius: 24px;
    padding: 58px 7px 48px 7px;
    position: relative;

    @include media-breakpoint-up(lg) {
        padding: 58px 24px 48px 24px;
    }
}

.introTextBlock {
    background-color: $light-red;
    height: 147px;
    margin-top: -165px;
}

.introTextBlockTransparent {
    background-color: transparent !important;
}

.button {
    background-color: $red;
    border: 3px solid $white;
    border-radius: 50%;
    color: $white;
    width: 46px;
    height: 46px;
    box-shadow: 0px 20px 40px rgba(0, 0, 0, 0.13), inset 0px 0px 12px rgba(0, 0, 0, 0.25);
    font-size: 11px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: -18px;
    padding: 0;

i {
        margin-top: 1px;
    }

    &:hover {
        background-color: #439ed5;
    }

    @include media-breakpoint-up(lg) {
        width: 66px;
        height: 66px;
        border-width: 5px;
        bottom: -32px;
        font-size: 16px;
    }
}

.withBackgroundImage {
    position: relative;
    margin-top: -35px;

    .header {
        @include media-breakpoint-up(lg) {
            padding: 105px 0;
        }
    }
}

.backgroundImage {
    background-size: cover;
    background-position: top center;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: -1;
    margin-top: -95px;

    @include media-breakpoint-up(lg) {
        margin-top: -145px;
        margin-bottom: 147px;
    }
}

.mb0 {
    margin-bottom: 0;
}

.desktopImage {
    border-radius: 24px;
    width: 100%;
    object-fit: none;
    height: 100%;
}
.mobileImage {
    border-radius: 24px;
    width: 100%;
    height: 100%;
    max-height: 385px;
    object-fit: cover;
}

.video {
    margin: 2rem 2rem 0 2rem;
    @include media-breakpoint-up(md) {
        margin: 2rem 0 0 0;
    }
    @include media-breakpoint-up(lg) {
        margin: 0 0 0 0;
    }
    @include media-breakpoint-up(md) {
        margin: 2rem 0 0 0;
    }
}