html {
    box-sizing: border-box;
}
  
*,
*::before,  
*::after {
    box-sizing: inherit;
}

@import "bootstrap/scss/_functions";
@import "./variables";
@import "bootstrap/scss/_mixins";
@import "bootstrap/scss/_reboot";
@import "bootstrap/scss/forms";
@import "bootstrap/scss/_grid";
@import "bootstrap/scss/_utilities";

.form-control:focus {
    border-color: $green;
}