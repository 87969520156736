@import '../../scss/lib/mixins';

.component {
    margin: 60px 0;
}

.titleContainer {
    display: flex;
    justify-content: center;
    width: 100%;
}

.title {
    position: relative;
    padding: 0 8px;
    font-size: 32px;
    line-height: 45px;
}

.panelRow {
    display: flex;
    flex-direction: column;
    margin-top: -20px;

    @include media-breakpoint-up(md) {
        flex-direction: row;
        margin: 0 -13px;
    }
}

.panel {
    background: $white;
    border-radius: 16px;
    padding: 12px;
    margin-top: 40px;

    @include media-breakpoint-up(md) {
        padding: 24px;
    }
}

.panelColumn {
    flex: 1;
    margin-top: 20px;

    @include media-breakpoint-up(md) {
        margin-top: 0;
        padding: 0 13px;
    }
}

.panelColumnTitle {
    font-size: 32px;
    line-height: 45px;
    color: $gray-800;
}

.text {
    font-size: 16px;
    line-height: 22px;
    color: $black
}

.image {
    width: 100%;
    height: auto;
}