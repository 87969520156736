@import '../../../scss/lib/mixins';

.details {
    padding: 24px 0 8px 0;
}

.detail {
    background: $white;
    border-radius: 16px;
    display: flex;
    padding: 16px;
    margin-bottom: 8;
    font-size: 16px;
    line-height: 22px;
    color: $gray-800;

    &:last-child {
        margin-bottom: 0;
    }
}

.detailLabel {
    width: 110px;
    font-weight: bold;

    @include media-breakpoint-up(lg) {
        width: 200px;
    }
}

.materialsLabel {
    width: auto;
    flex: 1;
}

.wrongDetailsText {
    font-size: 14px;
    line-height: 20px;
    color: $gray-800;
    margin-bottom: 34px;
}

.materialsValue {
    padding: 0 16px;
    display: flex;
    align-items: center;

    @include media-breakpoint-up(lg) {
        padding: 0 77px;
    }
}