@import "../../scss/lib/mixins";

.Wrapper {
    margin-top: 100px;
}

.Content {
    padding: 25px 0 75px 0;
}

a {
    cursor: pointer;
    &:hover{
        color: white;
    }
}

.Card {
    border-radius: 24px;
    background-color: $white;
    position: relative;

    &Content {
        padding: 55px 16px 25px 16px;

        @include media-breakpoint-up(lg) {
            padding: 80px 45px 40px 45px;
        }

        @include media-breakpoint-up(md) {
            padding: 55px 62px 25px 62px;
        }
    }
}

.Illustration {
    position: absolute;
    width: 70px;
    height: 70px;

    @include media-breakpoint-between(md, md) {
        width: 100px;
        height: 100px;
        left: 20px;
    }

    @include media-breakpoint-up(lg) {
        width: 100px;
        height: 100px;
        left: 40px;
        top: 20px;
    }


}

.HeaderWrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
}


.SubHeader {
    @include media-breakpoint-up(lg) {
        margin-bottom: -5px;
        z-index: 10;
    }

    @include media-breakpoint-up(md) {
        margin-bottom: -5px;
        z-index: 10;
    }
}

.blobs {
    top: -40px;
    height: 150px;
    width: 200px;
    left: 25%;

    @include media-breakpoint-between(md, md) {
        left: 36%;
    }

    @include media-breakpoint-up(lg) {
        height: 170px;
        width: 200px;
        top: -40px;
    }
}

.linkForLogin {
    font-weight: 900;
    font-size: 16px;
    position: relative;
    padding-left: 32px;
    color: white;
    &:before {
        content: url("../../assets/images/arrow.svg");
        position: absolute;
        left: 0;
        top: 1px;
    }
}

.linkWrapper {
    padding-top: 17px;
}

.headingPadding {
    padding: 8px 16px;
}

.noneBoxShadow {
    box-shadow: none;
}