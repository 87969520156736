$icomoon-font-family: "kpz" !default;
$icomoon-font-path: "fonts" !default;

$icon-DirectionLeft: "\e90b";
$icon-DirectionRight: "\e90c";
$icon-play: "\e90a";
$icon-right: "\e909";
$icon-arrow-down: "\e908";
$icon-navicon: "\f0c9";
$icon-close: "\e907";
$icon-edit: "\e906";
$icon-grabage: "\e903";
$icon-user: "\e905";
$icon-mark: "\e902";
$icon-place-board-left: "\e904";
$icon-school-board: "\e900";
$icon-place-board: "\e901";
$icon-euro-sign: "\20AC";


