@import "../../../scss/lib/mixins";

.label {
    color: $white;
    font-size: 16px;
    line-height: 21px;
    font-weight: bold;
    margin-bottom: 8px;
}

.input {
    outline: none;
    border: none;
    padding: 18px;
    box-shadow: 0px 0px 3px rgba($color: #000000, $alpha: 0.12);
    border-radius: 8px;
    font-weight: bold;
    font-size: 16px;
    line-height: 21px;
    color: $nero;
    width: 100%;

    &::placeholder {
        color: rgba($color: $nero, $alpha: 0.5)
    }
}