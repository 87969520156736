@import "../../../scss/lib/mixins";

.WrapperBlobs {
    position: relative;
}

.yellowBlob {
    position:absolute;
    content:url("../../../assets/images/yellow-blob.svg");
}

.blueBlob {
    position:absolute;
    content:url("../../../assets/images/blue-blob.svg");
}

.faqBlob {
    position:absolute;
}


.loginBlob {
    position:absolute;
}

.uspBlob {
    position:absolute;
}

.mySalesBlob {
    position:absolute;
}

.paragraphWithSideBlock {
    position:absolute;
}

.paragraphWithCards {
    position:absolute;
}

.video {
    position:absolute;
}

.videos {
    position:absolute;
}

.video-upload {
    position:absolute;
}

.video-carousel {
    position:absolute;
}

.homepage-header {
    position:absolute;
}

.content-page-header {
    position:absolute;
}