@import '../../../scss/lib/mixins';

.wrapper {
    background-color: white;
    border-radius: 16px;
    padding: 32px 32px 32px 32px;
    margin-bottom: 16px;  
    position: relative; 
}
.title {
    font-family: 'Marujo';
    font-size: 32px;
    line-height: 45px;
    color: $black;
    font-weight: bold;
    padding-bottom: 24px;
}

.content {
    padding-bottom: 22px;
}

.input {
    outline: none;
    border: none;
    border: 1px solid rgba(0, 0, 0, 0.12);
    border-radius: 8px;
    font-weight: bold;
    font-size: 16px;
    line-height: 21px;
    color: $nero;
    padding: 20px 20px 20px 60px;
    margin-right: 8px;
    margin-bottom: 10px;


    @include media-breakpoint-up(lg) {
        width: 310px;
        margin-bottom: 0;
    }
}

.inputWrapper
 {
    position: relative;
    &:before {
        content: url("../../../assets/images/mail.svg");
        position: absolute;
        left: 20px;
        top: 20px;
    }
 }
.emailForm{
    display: flex;
    flex-direction: column;
    
    @include media-breakpoint-between(md, md) {
        display: flex;
        flex-direction: column;
    }

    @include media-breakpoint-up(lg) {
        display: flex;
        flex-direction: row;
    }
}

.mailSend {
    padding-top: -8px;
    background-color: $cyan;
}

.approved{
    margin-bottom: 24px;
    background-color: $green;
}

.wrapperEmail{
    padding-top: -8px;
}

.rejected{
    margin-bottom: 24px;
    background-color: $light-pink;
}

.mailContent{
    padding: 24px;
}

.mailTitle{
    font-size:24px;
    line-height: 27px;
    font-family: 'Marujo';
    color: $white;
    margin-bottom: 8px;
    padding-left: 44px;
    position: relative;

    &:before {
        content: url("../../../assets/images/logo.svg");
        position: absolute;
        left: 0;
        top: 3px;
    }
}

.mailParagraph {
    color: $white;
    font-size: 18px;
}

.sendEmailAgain{
    margin-top: 17px;
    a {
        color: $light-pink;

        &:hover{
            text-decoration: underline;
        }
    }
}

.blob {
    z-index: -1;

    @include media-breakpoint-between(md, md) {
        display: flex;
        flex-direction: column;
    }

    @include media-breakpoint-up(lg) {
        right: -300px;
        top: 100px;
    }
}

.error {
    color: red;
    font-size: 14px;
}