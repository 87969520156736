@import "../../../scss/lib/mixins";

.Wrapper {
    display: flex;
    padding-top: 50px;
    margin-bottom: 13px;
}

.CircleWrapper {
    width: 22px;
    height: 22px;
    background-color: $light-pink;
    font-family: 'Marujo';
    font-size: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    color: $white;

    &Yellow {
        background-color: $yellow;
    }

    &Icon {
        position: relative;
    
        &:after {
            content: url("../../../assets/images/check.svg");
            position: absolute;
        }
      }
}

.Line {
    width: 50px;
    height: 2px;
    background-color: $light-pink;

    &Wide {
        width: 72px;
    }

    &Yellow {
        background-color: $yellow;
    }
}

.StepsNames {
    display: flex;
}

.Step {
    color: $white;
    font-weight: normal;
    font-size: 16px;
    line-height: 140%;
    padding-left: 35px;
    &:first-child {
        padding-left: 0;
    }
}

.StepActive {
    font-weight: 900;
}