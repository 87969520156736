@import "../../../../scss/lib/mixins";

.Wrapper {
    z-index: 100000000;
}

.arrow-icon {
    position: relative;
    &:after {
        content: url("../../../../assets/images/arrow-top.svg");
        position: absolute;
        right: 30;
        top: 26;
    }
}


.arrow-icon-rotated {
    position: relative;
    &:after {
        content: url("../../../../assets/images/arrow-top.svg");
        position: absolute;
        right: 30;
        top: 26;
        transform: rotate(180deg);
    }
}

.dropdown {
    z-index: 10000;
    
}