@import "../../scss/lib/mixins";

.faq {
    &-content {
        position: relative;
        margin-top: 32px;
        margin-bottom: 48px;

        @include media-breakpoint-up(lg) {
            margin-top: 42px;
            margin-bottom: 38px;
        }
    }

    &-paragraph {
        margin-top: 20px;
        margin-bottom: 28px;

        a {
            text-decoration: underline;

            &:hover,
            &:focus,
            &:active {
                color: $light-pink;
            }
        }

        @include media-breakpoint-up(lg) {
            margin-top: 28px;
        }
    }

    &-illustration {
        position: absolute;
        transform: rotate(-28.89deg);
        bottom: -54px;
        right: -28px;

        @include media-breakpoint-up(lg) {
            top: 0px;
            right: -180px;
        }
    }
}

.blobs {    
    top: 285px;
    
    @include media-breakpoint-up(lg) {
        right: -90px;
        top: 300px;
    }
}

.lightRedBackground {
    background-color: $light-red;
}