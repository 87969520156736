@import '../../../scss/lib/mixins';

.status {
    margin: 0;
}

.details {
    margin-top: 24px;
}

.detailsPadding {
    padding-top: 6px;
}