.game-submission-form {
    .umbraco-forms-label {
        margin-bottom: 8px;
        color: $white;
        font-size: 16px;
        font-weight: bold;
        margin-top: 24px;
    }

    .umbraco-forms-field-wrapper {
        input {
            height: 56px;
            max-width: 369px;
            border-radius: 8px;
            padding: 0 15px 0 15px;
            margin: 0 !important;
            text-align:start;
            font-size: 20px;
            border: 1px solid rgb(0, 0, 0);
            outline: none;
        }
    }

    .umbraco-forms-navigation {
        .btn  {
            margin-top: 28px;
            display: inline-flex;
            align-items: center;
            background-color: $blue;
            padding: 13px 24px 13px 56px;
            border: none;
            color: $white;
            text-align: center;
            text-decoration: none;
            cursor: pointer;
            border-radius: 12px;
            font-size: 16px;
            font-weight: bold;
            line-height: 21px;
            justify-content: center;
            position: relative;
            
            &:before {
                content: url("../../../assets/images/arrow.svg");
                position: absolute;
                left: 20px;
            }

            &:hover {
                background-color: darken($blue, 4%);
                color: $white;
            }

        }
    }

    .umbraco-forms-navigation .btn-loader {
        display: inline-flex;
        justify-content: center;
        min-height: 46px;
        padding-left: 24px;
        pointer-events: none;

        &:before {
            content: none;
        }

        &:after {
          content: '';
          background: url('../../../assets/images/oval.svg');
          width: 24px;
          height: 24px;
          display: inline-block;
          background-size: cover;
        }
        span {
          display: none;
        }
    }
}