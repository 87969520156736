@import '../../../scss/lib/mixins';

.componentWrapper {
  position: relative;
  margin-right: 33px;

  @include media-breakpoint-down(sm) {
    margin-right: 10px;
  }

  @include media-breakpoint-up(lg) {
    margin-right: 0;
    margin-left: 50px;
  }
}

.component {
  display: inline-flex;
  height: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  color: $white;
  cursor: pointer;

  span {
    font-size: 14px;
    line-height: 19px;
    user-select: none;

    @include media-breakpoint-down(sm) {
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      width: 100%;
      text-align: center;
    }
  }

  @include media-breakpoint-down(sm) {
    min-width: 80px;
    max-width: 115px;
    overflow: hidden;
    align-items: flex-start;
  }

  @include media-breakpoint-up(lg) {
    flex-direction: row;
    padding-right: 10px;

    span {
      font-weight: bold;
      font-size: 16px;
      line-height: 22px;
    }
  }
}

.circle {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #439ed5;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  margin-bottom: 5px;
  flex-shrink: 0;

  @include media-breakpoint-down(sm) {
    position: absolute;
    top: 10px;
    left: 50%;
    transform: translateX(-50%);
  }

  i {
    font-size: 12px;
    font-weight: bold;
    margin-left: 1px;
    margin-top: -2px;
  }

  @include media-breakpoint-up(lg) {
    width: 33px;
    height: 33px;
    margin-right: 8px;
    margin-bottom: 0;

    i {
      font-size: 16px;
    }
  }
}

.arrow {
  margin-left: 16px;
  display: none;
  font-size: 13px;
  margin-top: 2px;

  i {
    display: block;
    transform: rotateZ(0);
    transition: 0.5s transform;
  }

  @include media-breakpoint-up(lg) {
    display: block;
  }
}

.arrowOpen {
  i {
    transform: rotateZ(-180deg);
  }
}

.menuContainer {
  display: none;
  background-color: $white;
  position: absolute;
  width: 292px;
  top: 75px;
  z-index: 2020;
  border-radius: 24px;
  right: -70px;
  padding: 24px;

  @include media-breakpoint-up(lg) {
    right: 0;
  }
}

.menuIsOpen {
  display: block;
}

.menuTitle {
  font-size: 18px;
  line-height: 21px;
  color: $gray-800;
  margin-bottom: 25px;
}

.menuList {
  list-style: none;
  margin: 0;
  padding: 0;
  padding-bottom: 36px;

  li {
    margin-bottom: 20px;
    padding-left: 37px;
    position: relative;

    &:last-child {
      margin-bottom: 0;
    }
  }

  a {
    color: $red;
    font-size: 16px;
    line-height: 18px;
    font-weight: 500;

    &:hover {
      font-weight: 900;
      color: $red;
    }
  }

  img {
    position: absolute;
    width: 24px;
    height: 24px;
    left: 0;
  }
}

.activeMenu {
  a {
    font-weight: 900;
  }
}

.button {
  min-height: 54px;
}
