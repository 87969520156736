@import '../../scss/lib/mixins';

.wrapper {
    display: none;
    width: 100%;
    padding: 16px 24px;
    z-index: 20;
    background-color: $white;
}

.wrapperActive {
    display: flex;
}

.innerWrapper {
    display: flex;
    margin: 0 auto;
    max-width: 1160px;
}

@media (min-width: 1024px) {
    .innerWrapper {
        align-items: center;
    }
}

@media (min-width: 1024px) {
    .sidebar {
        margin-left: 8px;
    }
}

.content {
    display: flex;
    flex-direction: column;
    padding-right: 16px;
}

@media (min-width: 1024px) {
    .content {
        padding-right: 64px;
    }
}

.content p {
    margin-top: 0;
    margin-bottom: 12px;
}

.illustration {
    flex-shrink: 0;
    width: 64px;
    margin-right: 32px;
}

@media (max-width: 1023px) {
    .illustration {
        display: none;
    }
}

@media (min-width: 1024px) {
    .ctaMobile {
        display: none;
    }
}

.ctaDesktop {
    flex-shrink: 0;
}

@media (max-width: 1023px) {
    .ctaDesktop {
        display: none;
    }
}

.modalClose {
    border: none;
    outline: none;
    background-color: $red;
    color: $white;
    border-radius: 8px;
    width: 46px;
    height: 46px;
    display: flex;
    justify-content: center;
    align-items: center;

    &:hover {
        background-color: darken($red, 4%);
    }

    i {
        transform: scale(0.8);
    }
}