@import "../../scss/lib/mixins";

.backButtonStyle {
    color: $white;
    display: inline-flex;
    align-items: center;
    font-size: 16px;
    margin-bottom: 19px;

    @include media-breakpoint-up(lg) {
        margin-bottom: 27px;
    }

    span {
        padding-left: 12px;
    }

    &:hover {
        span {
            color: $blue;
        }
    }
}

.kidNameCode {
    display: inline-block;
    margin-left: 16px;
    font-size: 22px;
    position: relative;
    top: -7px;

    @include media-breakpoint-up(lg) {
        font-size: 32px;
    }
}

.kidName {
    padding-top: 6px;
    white-space: nowrap;
    font-size: 32px;

    @include media-breakpoint-up(lg) {
        font-size: 56px;
    }

    img {
        margin-right: 21px;
        width: 51px;
    }
}

.table {
    width: 100%;
    margin-top: 26px;
    color: $dark;

    td {
        height: 93px;

        @include media-breakpoint-up(lg) {
            height: 72px;
        }
    }
}

.turnOverColumn {
    color: #439ed5;
    font-weight: 700;
    width: 1px;
    white-space: nowrap;
    font-size: 16px;

    @include media-breakpoint-up(lg) {
        font-size: 18px;
    }

    span {
        @include media-breakpoint-up(lg) {
            padding-right: 50px;
        }
    }
}

.numberColumn {
    padding-left: 24px !important;
    width: 70px;
    font-size: 18px;
    font-family: Marujo;
    opacity: 0.6;
}

.noOpacity {
    opacity: 1;
}

.typeColumn {
    font-weight: bold;
    font-size: 18px;
    margin-bottom: 2px;

    @include media-breakpoint-up(lg) {
        margin-bottom: 0;
    }
}

.typeWithIcon {
    display: flex;
    align-items: center;

    &:before {
        content: url('../../assets/images/pc-black.svg');
        display: block;
        margin-right: 17px;
    }
}

.typePaperWithIcon {
    display: flex;
    align-items: center;

    &:before {
        content: url('../../assets/images/paper.svg');
        display: block;
        margin-right: 17px;
        margin-left: 5px;
    }
}

.totalRow {
    background-color: $yellow !important;
    height: 60px;
    pointer-events: none;
}

.totalLabel {
    padding-left: 20px !important;
    font-weight: bold;
    font-size: 21px;

    @include media-breakpoint-up(lg) {
        padding-left: 10px !important;
    }
}

.mobileRow {
    display: flex;
    align-items: center;
    margin-left: 10px;
    margin-right: 10px;

    &:before {
        content: url('../../assets/images/pc.svg');
        display: block;
        margin-right: 17px;
    }
}

.mobilePaperRow {
    &:before {
        content: url('../../assets/images/paper-blue.svg');
        margin-left: 5px;
    }
}

.rowNumberMobile {
    font-family: Marujo;
    padding-bottom: 8px;
    color: $dark;
    font-size: 18px;
    line-height: 21px;
    opacity: 0.6;
}

.illustration {
    position: absolute;
    right: 0;
    width: 104px;
    transform: rotate(-17.97deg);
}

.mobileTotalNumber {
    margin-right: 11px;
}