@import '../../../scss/lib/mixins';

.button {
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: space-evenly;
    padding: 24px 40px;
    border-radius: 24px;
    text-align: center;
    margin: 10px;
    width: 100%;
    position: relative;

    @include media-breakpoint-down(sm) {
        margin: 30px auto 0;
    }
}

.buttonMobile {
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: space-evenly;
    padding: 20px 40px;
    border-radius: 24px;
    text-align: center;
    margin: 30px auto 0;
    max-width: 316px;
    position: relative;
}

.button:hover {
    transform: rotate(0deg);
    transition-duration: 0.5s;
    cursor: pointer;
    transform: scale(1.05);
}

.illustration {
    width: 60px;
    height: auto;
    position: absolute;
}

.leftIllustration {
    left: -7%;
    bottom: 4%;
    
}

.rightIllustration {
    right: 5%;
    top: -12%;
}

.leftButton {
    
    background-color: #038ABC;
    @include media-breakpoint-up(md) {
        transform: rotate(-3deg);
    }
}

.rightButton {
    background-color: #FED100;
    @include media-breakpoint-up(md) {
        transform: rotate(2deg);
    }
}

.subTitle {
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    margin: 12px 0 16px 0;
    color: white;
}

.navigationButton {
    height: 52px;
    width: 52px;
    border-radius: 16px;
    border: none;
    color: white;
}

.navigationButtonLeft {
    background-color: #522398;
}

.navigationButtonRight {
    background-color: #009FDA;
}

.logoContainer {
    width: 64px;
    margin: auto;
    height: 65px;
}