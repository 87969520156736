@import "../../../scss/lib/mixins";

.questions {
    &-wrapper {
        display: flex;
        padding-bottom: 95px;
        flex-direction: column;
        @include media-breakpoint-up(lg) {
            padding-bottom: 148px;
            flex-direction: row;
        }
    }

    &-questions {
        width: 100%;
        @include media-breakpoint-up(lg) {
            width: 65%;
        }
    }

    &-subjects {
        &-wrapper {
            padding-bottom: 16px;
            box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.06);

            @include media-breakpoint-up(lg) {
                padding: 32px 24px;
                background-color: $white;
                margin-bottom:30px;
                border-radius: 24px;
            }
        }
        margin-right: 32px;
        width: 100%;
        @include media-breakpoint-up(lg) {
            margin-left: 0;
            width: 35%;
        }
    }
}

.subject {
    font-weight: 900;
    font-size: 16px;
    position: relative;
    cursor: pointer;
    margin-bottom: 14px;
    display: flex;
    align-items: center;

    &:last-child{
        margin-bottom: 0;
    }
}

.questions {
    padding-bottom: 95px;
    @include media-breakpoint-up(lg) {
        padding-bottom: 120px;
    }
}

.subject-heading {
    font-weight: 900;

    @include media-breakpoint-up(lg) {
        padding-bottom: 15px;
    }
}

.subject-selected {
    font-weight: 900;
    font-size: 16px;
    position: relative;
    cursor: pointer;
    margin-bottom: 14px;
    display: flex;
    align-items: center;

    &:last-child{
        margin-bottom: 0;
    }
    color: $blue;
}

.subject-selected,
.subject {
    i {
        font-size: 20px;
    }
}

.subject i {
    margin-right: 13px;
}

.subject-selected i {
    margin-right: 19px;
}