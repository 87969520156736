@import '../../scss/lib/mixins';

.panel {
    margin-top: 24px;
    background: $white;
    border-radius: 16px;
    padding: 16px;
    font-size: 18px;
    line-height: 25px;
}

.sendText {
    margin-bottom: 16px;
}