@import '../../scss/lib/mixins';

.component {
    display: flex;
    flex-direction: column;
    height: 100vh;
}

.header {
    height: 110px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;

    img {
        width: 111px;
    }
}

.body {
    flex-grow: 1;
    margin: 0 5px 5px 5px;
    background-color: $light-red;
    border-bottom-left-radius: 12px;
    border-bottom-right-radius: 12px;
    position: relative;
    padding: 40px 0;
}

.heading {
    background-color: $cyan;
    color: $white;
    box-shadow: 0px 14px 10px rgba($color: $black, $alpha: 0.06);
    font-size: 32px;
    line-height: 45px;
    padding: 0 8px;
    left: 0;
}

.text {
    margin-top: 80px;
    font-size: 16px;
    line-height: 22px;
    color: $gray-800;

    @include media-breakpoint-up(lg) {
        margin-top: 56px;
    }

    ol {
        padding-left: 25px;
    }
}

.buttonContainer {
    margin-top: 40px;
    margin-left: -12px;
    margin-right: -12px;

    button {
        margin-left: 12px;
        margin-right: 12px;
        margin-bottom: 20px;

        @include media-breakpoint-up(lg) {
            margin-bottom: 0;
        }
    }
}

.buttonNo {
    font-weight: 900;
}


.video {
    margin-top: 60px;
    width: 100%;
    position: relative;

    @include media-breakpoint-up(lg) {
        margin-top: 110px;
    }
}

.videoInstance {
    position: relative;
    z-index: 2;
}

.videoBlob {
    position: absolute;
    z-index: 1;
    top: -160px;
    left: -160px;
    transform: scale(0.5);

    
    @include media-breakpoint-up(md) {
        transform: scale(0.8);
        top: -102px;
        left: -128px;
    }

    @include media-breakpoint-up(lg) {
        left: -120px;
        top: -110px;
        transform: none;
    }
}

.illustrationUpper {
    position: absolute;
    right: -72px;
    top: -38px;
}

.illustrationLower {
    position: absolute;
    bottom: -59px;
    left: 100px;

    @include media-breakpoint-up(md) {
        left: 200px;
    }

    @include media-breakpoint-up(lg) {
        left: 300px;
    }
}