@import '../../../scss/lib/mixins';

.logo {
    background-image: url('../../../assets/images/Happy-KPZ.svg');
    width: 32px;
    height: 31px;
    flex-shrink: 0;
    margin-top: -3px;
}

.message {
    padding: 24px 18px 19px 24px;
    color: $white;
    margin-top: 36px;
}

.success {
    background-color: $green;
}

.reject {
    background-color: $light-pink;
}

.title {
    display: flex;
    align-items: center;
    font-family: Marujo;
    font-size: 24px;
    line-height: 27px;
}

.titleContent {
    padding-left: 10px;
}

.text {
    font-size: 18px;
    font-weight: 600;
    line-height: 25px;
    margin-top: 2px;
}