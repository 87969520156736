@import '../../../scss/lib/mixins';

.wrapper {
 background-color: white;
 border-radius: 16px;
 padding: 32px;
 margin-bottom: 16px;
 position: relative;   
}

.title {
    font-family: 'Marujo';
    font-size: 32px;
    line-height: 45px;
    color: $black;
    padding-bottom: 24px;
}

.button {
    padding-top: 22px;
}

.blob {
    z-index: -1;

    @include media-breakpoint-between(md, md) {
        display: flex;
        flex-direction: column;
    }

    @include media-breakpoint-up(lg) {
        right: -300px;
        top: 100px;
    }
}

.pageButton {
    margin-top: 20px;
}