@import '../../../scss/lib/mixins';

.componentWrapper {
    margin-top: 60px;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
}

.infoBoxes {
    display: flex;
    flex-direction: column;
    margin: 0 -10px;
    color: $dark-red;
    width: 100%;
    margin-bottom: 0;

    @include media-breakpoint-up(lg) {
        width: 90%;
        margin-bottom: 50px;
    }

    @include media-breakpoint-up(lg) {
        flex-direction: row;
    } 

    .pinkBox {
        background-color: #E74697;
    }

    .blueBox {
        background-color: $blue;
    }
}

.infoBox {
    position: relative;
    flex-basis: 50%;
    margin: 0 10px;
    margin-bottom: 20px;
    padding: 30px 0;
    text-align: center;
    background-color: $white;
    box-shadow: 0px 15px 10px rgba(0, 0, 0, 0.06);
    border-radius: 12px;
    font-size: 60px;
    line-height: 65px;
    font-weight: bold;
    display: flex;
    flex-direction: column;
    justify-content: center;

    @include media-breakpoint-up(lg) {
        font-size: 80px;
        margin-bottom: 0;
        padding: 30px 10px 48px 10px;
    }

    &NegativeTransform {
        @include media-breakpoint-up(lg) {
            transform: rotate(-2deg);
            min-height: 220px;
        }
    }

    &PositiveTransform {
        @include media-breakpoint-up(lg) {
            transform: rotate(2deg);
            margin-top: 20px;
        }
    }
}

.infoBoxTitle {
    font-family: 'Marujo';
    font-size: 32px;
    line-height: 40px;
    font-weight: normal;
    color: $dark-red;
}

.colorWhite {
    color: white;
}

.Illustration {
    position: absolute;
    top: -20%;
    right: 10px;
    transform: rotate(-15deg);
}

.blobs {
    width:417px;
    height:375px;
    z-index: -10;
    top: -65px;
    right: -147px;

    @include media-breakpoint-up(xl) {
        top: -52px;
        left: 397px;
    }

    @include media-breakpoint-between(lg, lg) {
        top: -52px;
        left: 313px;
    }
}

.buttonContainer {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: -25px;
    width: 100%;

    @include media-breakpoint-up(lg) {
        bottom: -37px;
    }
}

.button {
    @include media-breakpoint-up(lg) {
        height: 72px;
    }
}

.buttonWithArrow {
    @include media-breakpoint-up(lg) {
        width: 72px;
        height: 72px;
    }

    &:before {
        transform: scale(1.4) !important;
    }
}

.withButtonArrow {
    margin-bottom: 40px;

    @include media-breakpoint-up(lg) {
        margin-bottom: 0;
    }
}