@import '../../scss/lib/mixins';

.header {
  display: flex;
  justify-content: center;
}

.componentWrapper {
  margin-top: 60px;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  padding-bottom: 5rem;
}

.title {
  position: relative;
  font-size: 18px;
  line-height: 21px;
  left: 0;
  padding: 8px 16px;
  box-shadow: 0px 15px 10px rgba(0, 0, 0, 0.06);
  background-color: white;
  color: $dark-orange;
}

.navigation {
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.tooltip {
  align-self: center;
}

.infoIcon {
  font-size: 40px;
  align-self: center;
  @include media-breakpoint-up(lg) {
    font-size: 60px;
  }
}

.navigationTitle {
  color: $white;
  margin: 12px 0 12px 0 !important;
  text-transform: uppercase;
  font-size: 32px;
  line-height: 37px;
  text-align: center;

  @include media-breakpoint-up(lg) {
    font-size: 90px;
    line-height: 99px;
  }
}

.blobs {
  width: 417px;
  height: 375px;
  z-index: -10;
  top: -65px;
  right: -147px;

  @include media-breakpoint-up(xl) {
    top: -52px;
    left: 397px;
  }

  @include media-breakpoint-between(lg, lg) {
    top: -52px;
    left: 313px;
  }
}

.userNameContainer {
  display: flex;
  justify-content: center;
  gap: 30px;
}

.editButton {
  width: 32px;
  height: 32px;
  background-color: rgba($color: $white, $alpha: 0.2);
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: center;
  color: $white;
  cursor: pointer;

  @include media-breakpoint-up(lg) {
    width: 52px;
    height: 52px;
    i {
      font-size: 32px;
    }
  }
}

.links {
  display: none;
  justify-content: center;

  @include media-breakpoint-up(lg) {
    display: flex;
    width: 80%;
    margin: auto;
  }
}

.link {
  margin: 0 7px;
  background-color: $dark-red;
  box-shadow: 0px 15px 10px rgba(0, 0, 0, 0.06);
  border-radius: 12px;
  padding: 16px 24px;
  font-weight: 900;
  font-size: 16px;
  line-height: 22px;
  color: $white;

  &:hover {
    background-color: darken($dark-red, 4%);
  }
}

.active {
  background-color: darken(#439ed5, 4%);
}

.form {
  display: flex;
  margin: 12px 0;
  justify-content: center;
  gap: 10px;

  @include media-breakpoint-up(lg) {
    width: 80%;
    align-items: center;
    gap: 30px;
  }
}

.input {
  background-color: transparent;
  border: none;
  text-align: center;
  border-bottom: 1px solid $white;
  outline: none;
  font-family: 'Marujo';
  font-size: 32px;
  line-height: 37px;
  color: $white;
  width: 100%;

  @include media-breakpoint-up(md) {
    font-size: 60px;
    line-height: 68px;
  }

  @include media-breakpoint-up(lg) {
    font-size: 90px;
    line-height: 99px;
  }

  &::placeholder {
    color: rgba($color: $white, $alpha: 0.8);
  }
}

.button {
  margin-left: 10px;

  @include media-breakpoint-up(lg) {
    height: 72px;
  }
}

.removeMargin {
  margin: 0;

  @include media-breakpoint-up(lg) {
    display: flex;
    justify-content: center;
  }
}

.input_error_message {
  color: $dark-red;
}

.inputErrorMessage {
  color: $dark-red;
  margin-top: 4px;
  font-weight: 700;
}
