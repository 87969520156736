
@font-face {
    font-family: 'Exo';
    src:  url('../../assets/fonts/Exo/Exo2-Regular.ttf') format('truetype');
    font-weight: 400;
}



@font-face {
    font-family: 'Exo';
    src:  url('../../assets/fonts/Exo/Exo2-SemiBold.ttf') format('truetype');
    font-weight: 600;
}

@font-face {
    font-family: 'Exo';
    src:  url('../../assets/fonts/Exo/Exo2-SemiBoldItalic.ttf') format('truetype');
    font-weight: 400;
    font-style: italic;
}

@font-face {
    font-family: 'Exo';
    src:  url('../../assets/fonts/Exo/Exo2-Bold.ttf') format('truetype');
    font-weight: 700;
}

@font-face {
    font-family: 'Exo';
    src:  url('../../assets/fonts/Exo/Exo2-Italic.ttf') format('truetype');
    font-weight: 400;
    font-style: italic;
}

@font-face {
    font-family: 'Exo';
    src:  url('../../assets/fonts/Exo/Exo2-ExtraBold.ttf') format('truetype');
    font-weight: 900;
}


@font-face {
    font-family: 'Exo';
    src:  url('../../assets/fonts/Exo/Exo2-ExtraBoldItalic.ttf') format('truetype');
    font-weight: 900;
    font-style: italic;
}

@font-face{
    font-family:'Marujo';
    src:url("../../assets/fonts/Marujo/Regular/Alternative_files/Marujo.ttf");
    font-weight: 400;
}
