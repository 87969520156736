@import "../../../scss/lib/mixins";

.menu-item {
    padding-top: 12px;
    padding-bottom: 12px;

    @include media-breakpoint-up(lg) {
        padding: 0 20px;
    }
}

.navigation-link {
    font-size: 14px;
    line-height: 19px;
    font-weight: 900;
    text-transform: uppercase;
    color: $white;

    &:hover {
        border-bottom: 2px solid $white;
    }

    @include media-breakpoint-up(lg) {
        font-size: 16px;
        line-height: 21px;
    }
}

.active{
    color: $yellow;
    pointer-events: none;
}