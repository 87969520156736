@import "../../scss/lib/mixins";


.wrapper {
    display: flex;
    align-items: center;
    flex-direction: column;
}

.imageWrapper {
    margin-top: 27px;
    margin-bottom: 50px;
    height: 180px;
    width: 180px;
    border-radius: 12px;
    position: relative;
        
    @include media-breakpoint-up(md) {
        height: 450px;
        width: 450px;
    }
}

.title{
    color: $white;
    font-family: 'Marujo';
    font-size: 60px;
    line-height: 60px;
    text-align: center;
    transform: rotate(-2deg);
    
    @include media-breakpoint-up(md) {
        font-size: 90px;
        line-height: 100px;
    }
}

.image {
    height: 100%;
    width: 100%;
    border-radius: 12px;
}

.buttons {
    margin-top: 50px;
}

.blob {
    height: 220px;
    width: 220px;
    z-index: -1;
    left: -20px;

    @include media-breakpoint-up(md) {
        width: 585px;
        height: 500px;
        left: -50px;
    }
}

.illustration1 {
    position: absolute;
    width: 60px;
    left: -70px;
    top: 70px;
    @include media-breakpoint-up(md) {
        width: 100px;
        left: -110px;
        top: 210px;
    }

}

.illustration2 {
    position: absolute;
    width: 60px;
    right: -55px;
    top: 20px;
    @include media-breakpoint-up(md) {
        width: 100px;
        right: -90px;
        top: 30px;
    }
}

.illustration3 {
    z-index: -1;
    position: absolute;
    width: 60px;
    bottom: -45px;
    right: -15px;

    @include media-breakpoint-up(md) {
        bottom: -80px;
        right: -20px;
        width: 100px;
    }
}

.marginBottom {
    margin-top: 90px;
    margin-bottom: 80px;
}