@import "../../../../scss/lib/mixins";

.Wrapper {
    margin-top: 60px;
}

.AutoComplete {
    padding-bottom: 80px;
}
.overlay {
    z-index: 99999;
    position: relative;
}