form {
    .form-control {
      box-sizing: none;
      font-size: 16px;
      line-height: 21px;
    }

    label {
        font-weight: bold;
        color: $black;
        font-size: 16px;
        line-height: 21px;
    }
}