@import "../../../src/scss/lib/mixins";

.container {
    background-color: white;
    display: flex;
    align-items: center;
    flex-direction: column;
    padding-top: 20px;
}

.logo {
    height: auto;
    width: 110px;
    margin: auto;
}

.borderContainer {
    margin-top: 10px;
    width: 130%;
}

.imgBorderSecond {
    @include media-breakpoint-up(sm) {
        width: 50%;
        height: auto;
    }

    @include media-breakpoint-down(sm) {
        display: none;
    }
  
}

.imgBorder {
    width: 50%;
    height: auto;

    @include media-breakpoint-down(sm) {
        width: 100%;
    }
}