@import "../../scss/lib/mixins";

.paragraph-with-cards {
    &-content {
        padding-top: 35px;
        padding-bottom: 77px;
        position: relative;
        
        @include media-breakpoint-up(lg) {
            padding-top: 42px;
            padding-bottom: 45px;
        }
    }

    &-paragraph {
        padding-bottom: 13px;
        padding-top: 20px;
        font-size:16px;
        line-height: 22px;

        a {
            text-decoration: underline;

            &:hover,
            &:focus,
            &:active {
                color: $light-pink;
            }
        }
                
        @include media-breakpoint-up(lg) {
            padding-top: 24px;
        }
    }

    &-cards {
        padding-top: 32px;
        position: relative;
        z-index: 10;

        @include media-breakpoint-up(lg) {
            padding-top: 64px;
        }    
    }
}

.card--variable-height {
    height: auto;
}

.card-wrapper {
    margin-bottom: 16px;
    
    &:last-child {
        margin-bottom: 0;
    }
    
    @include media-breakpoint-up(lg) {
        margin-bottom: 64px;

        &:nth-child(2) {
            margin-top: 32px;
            margin-bottom: 32px;
        }

        &:nth-child(3) {
            margin-bottom: 64px;
        }
    }

    > a {
        display: flex;
        align-items: flex-end;
        height: 100%;
    }
}

.illustration {
    position: absolute;
    right: 0;
    top: 5px;
    transform: rotate(-28.89deg);
    display: none;

    @include media-breakpoint-up(lg) {
        right: 100px;
        display: block;
    }
}

.blob {
    top: 50%;
    transform: translatey(-50%);
    margin: 0;
    right: -80px;
    left: auto;

    @include media-breakpoint-up(lg) {
        transform: none;
        left: 0; 
        right: 0; 
        margin-left: auto; 
        margin-right: auto;
        top: 210px;
    }
}

.card--variable-height {
    height: auto;
}

.rowIllustration {
    position: absolute;
    transform: rotate(-28.89deg);
    right: 10px;
    bottom: -50px;

    @include media-breakpoint-up(lg) {
        bottom: -10px;
        right: 65px;
    }
}

.lightRedBackground {
    background-color: $light-red;
}