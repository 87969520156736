.illustration {
    margin-top: -30px;

    img {
        width: 150px;
    }
}

.title {
    margin-top: 10px;
    font-weight: bold;
}

.body {
    font-size: 16px;
    line-height: 22px;
}

.button {
    margin-top: 32px;
}