@import "../../../../scss/lib/mixins";


.card {
    border-radius: 16px;
    background-color: $white;
    max-width: 350px;
    height: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
    box-shadow: 0px 30px 30px rgba(0, 0, 0, 0.05);

    @include media-breakpoint-up(md) {
        width: 350px;
    }

    &-content {
        position: relative;
     padding: 24px 16px;
     height: 100%;
     display: flex;
     flex-direction: column;

        @include media-breakpoint-between(sm, md) {
            flex-basis: 50%;
        }
    } 

    &-image-wrapper {
        height: 186px;
        border-top-left-radius: 16px;
        border-top-right-radius: 16px;
        overflow: hidden;
        position: relative;
        flex-shrink: 0;
    }

    &-paragraph {
        padding-top: 12px;
        padding-bottom: 10px;
        color: $gray-800;
        flex-grow: 1;

        a {
            text-decoration: underline;

            &:hover,
            &:focus,
            &:active {
                color: $light-pink;
            }
        }
    }

    &-button {
        width: 100%;
        text-align: right;
    }

    &-button-left {
        display: flex;
        align-items: center;
        padding-right: 15px;
        border-radius: 12px;
    }

    &-first-link {
        color: #439ed5;
        font-size: 16px;
        font-weight: bold;
        padding-left: 14px;
    }

    &-link {
        color: #439ed5;
        font-size: 16px;
        font-weight: bold;
        padding-left: 14px;
        max-width: 270px;
    }

    &-image {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);

        @include media-breakpoint-between(sm, md) {
            height: 100%;
        }
    }
}

.illustration {
    bottom: -60px;
    right: 0;
    position: absolute;
}

.firstCard {
    width: 540px;
    max-width: 600px;
    @include media-breakpoint-down(md) {
        max-width: 327px;
        width: 340px;
    }

    @include media-breakpoint-down(sm) {
        max-width: 100%;
    }
}

.firstCardImage {
    @include media-breakpoint-up(md) {
        height: 255px;
    }
}
.link {
    display: flex;
    align-items: center;
    &:hover {
        span {
            color:$white;
        }

        .card-button-left {
            background-color: #439ed5;
        }
    }   
}