@import "../../../scss/lib/mixins";

a  {
    &:hover {
        p {
          color: $black;
        }

        span{
            color:$white;
        }

        .card-button-left {
            background-color: #439ed5;
        }
    }

}
.card {
    border-radius: 16px;
    background-color: $white;
    cursor: pointer;
    width: 100%;
    height: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
    box-shadow: 0px 30px 30px rgba(0, 0, 0, 0.05);

    @include media-breakpoint-between(sm, md) {
        flex-direction: row;
        overflow: hidden;
    }

    &-content {
     padding: 24px 16px;
     height: 100%;
     display: flex;
     flex-direction: column;

        @include media-breakpoint-between(sm, md) {
            flex-basis: 50%;
        }
    } 

    &-image-wrapper {
        height: 186px;
        border-top-left-radius: 16px;
        border-top-right-radius: 16px;
        overflow: hidden;
        position: relative;
        flex-shrink: 0;
        
        @include media-breakpoint-between(sm, md) {
            flex-basis: 50%;
            display: flex;
            align-items: center;
            border-top-left-radius: 0;
            border-top-right-radius: 0;
            height: 100%;
        }
    }

    &-paragraph {
        padding-top: 12px;
        padding-bottom: 10px;
        pointer-events:none;
        color: $gray-800;
        flex-grow: 1;
    }

    &-button {
        width: 100%;
        text-align: right;
    }

    &-button-left {
        display: flex;
        align-items: center;
        padding-right: 15px;
        border-radius: 12px;
    }

    &-link {
        color: #439ed5;
        font-size: 16px;
        font-weight: bold;
        padding-left: 14px;
    }

    &-image {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);

        @include media-breakpoint-between(sm, md) {
            height: 100%;
        }
    }
}

.card-fixed {
    width: 100%;

    @include media-breakpoint-up(lg) {
        max-width: 256px;
        top: 0;
    }
}

.cursorDefault {
    cursor: default;
}

.card--without-image {
    height: auto;
}