@import '../../../scss/lib/mixins';

.mobileWrapper {
    display: flex;
    flex-direction: column;
    margin-top: 80px;
}

.wrapper {
    flex-direction: column;
    display: flex;
}

.mobileCard {
    margin-top: 140px;
}

.leftCard {
    margin-left: 175px;
}

.rightCard {
    align-self: flex-end;
    margin-right: 175px;
}

.centerCard {
    margin-top: 200px;
    align-self: center;
}

.secondCard {
    margin-top: 100px;
}

.thirdCard {
    margin-top:  -64px;
}

.fiftCard {
    margin-top: 60px;
}

.sixthCard {
    margin-top: 60px;
}

.seventhCard {
    margin-top: 120px;
}

.eightCard {
    margin-top: 140px;
}