.component-panel {
    background-color: $light-red;
    border-radius: 25px;
    padding: 32px 10px;

    @include media-breakpoint-up(lg) {
        padding: 32px;
    }
}

.panel-position {
    margin-top: 15px;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
  }
  @media (min-width: 768px) {
    .panel-position {
      width: 750px;
    }
  }
  @media (min-width: 992px) {
    .panel-position {
      width: 970px;
    }
  }
  @media (min-width: 1200px) {
    .panel-position {
      width: 1170px;
    }
  }

.section-title {
    display: flex;
    align-items: center;
    
    &-number {
        width: 22px;
        height: 22px;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        color: $white;
        background-color: $cyan;
        font-size: 16px;
        line-height: 18px;
        padding-bottom: 1px;
    }

    &-text {
        font-family: Marujo;
        font-size: 24px;
        line-height: 27px;
        color: $gray-800;
        margin-left: 8px;
    }
}

.order-form {
    padding: 16px 0;

    .umbraco-forms-form {
        padding: 0;
        background-color: $light-red !important;

        .umbraco-forms-fieldset {
            padding: 0;
            margin: 0;
        }

        label {
            margin-bottom: 8px;
        }

        input.text {
            margin: 0 0 26px 0;
            border: 1px solid $gray-600;
            box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.12);
            border-radius: 8px;
            height: 56px;
            outline: none;
            font-size: 16px;
            line-height: 21px;
            color: $gray-800;
            max-width: 544px !important;
        }

        span.field-validation-error {
            margin-top: -20px;
            display: block;
        }
    }

    .umbraco-forms-label {
        font-size: 16px;
        line-height: 21px;
        font-weight: lighter;
        color: $gray-800;
    }
}

.order-field {
    background: $white;
    border-radius: 16px;
    padding: 16px;
    margin-bottom: 4px;
    display: flex;
    align-items: center;

    @include media-breakpoint-up(lg) {
        padding: 16px 24px
    }

    &:last-child {
        margin-bottom: 0;
    }

    &-label {
        flex: 1;
        font-weight: bold;
        font-size: 16px;
        line-height: 21px;
        color: $gray-800;
        padding-right: 20px;
    }

    &-input {
        input {
            width: 56px !important;
            height: 56px !important;
            text-align: center;
            margin: 0 !important;
        }
    }
}