@import "../../../scss/lib/mixins";
@import '../../../assets/fonts/Icons/variables';

.paparSale {
    padding: 34px 0;
}

.paperSaleTitle {
    font-size: 32px;
    line-height: 45px;
    position: static;
    box-shadow: 0px 15px 10px rgba($color: #000000, $alpha: 0.06);
}

.paperSaleSubtitle {
    color: $white;
    padding-top: 20px;
    font-size: 16px;
    line-height: 22px;
}

.formContainer {
    padding-top: 24px;
}

.inputPerson,
.inputSales {
    position: relative;

    input {
        padding-left: 48px;
    }
}

.inputPerson {
    &:before {
        content: $icon-user;
        font-family: $icomoon-font-family;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        margin-left: 16px;
        font-size: 19px;
    }
}

.inputSales {
    &:before {
        content: $icon-euro-sign;
        font-family: $icomoon-font-family;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        margin-left: 16px;
        font-size: 19px;
    }
}

.buttonContainer {
    height: 100%;
    display: flex;
    align-items: flex-end;

    button {
        height: 56px;
    }
}

.rowMargin {
    margin-left: -10px;
    margin-right: -10px;
}

.columnPadding {
    padding-bottom: 20px;
    padding-left: 10px;
    padding-right: 10px;

    @include media-breakpoint-up(lg) {
        padding-bottom: 0;
    }
}

.inputErrorMessage {
    color: $light-pink;
    font-weight: 700;
}