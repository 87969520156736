@import '../../../../scss/lib/mixins';

.videoTitle {
    font-family: 'Marujo';
    font-size: 24px;
    line-height: 27px;
    color: $black;
    margin-bottom: 16px;
}

.videoWrapper {
    margin-top: 32px;
}

.subtitle {
    margin-top: 8px;
    font-size: 12px;
    color :rgba(0, 0, 0, 0.5)
}

.label{
    display: inline-flex;
    align-items: center;
    background-color: $light-pink;
    padding: 13px 24px;
    border: none;
    color: $white;
    text-align: center;
    text-decoration: none;
    cursor: pointer;
    border-radius: 12px;
    font-size: 16px;
    font-weight: bold;
    line-height: 21px;
    justify-content: center;
    position: relative;
    padding-left: 56px;

    &:before {
        content: url("../../../../assets/images/rectangular-check.svg");
        position: absolute;
        left: 20px;
    }
}

.video {
    width: 100%;
}

.uploadVideoText {
    margin-top: 16px;
    margin-bottom: 8px;
    font-size: 16px;
}

.approved {
    margin-top: 8px;
    padding: 16px;
    background-color: $green;
}

.paragraph {
    color: $white;
    font-size: 18px;
    line-height: 25px;
}

.rejected {
    padding: 16px;
    background-color: $light-pink;
    margin-top: 20px;
}


.reported {
    padding: 16px;
    background-color: $light-pink;
    margin-top: 20px;
}

.waiting{
    margin-top: 8px;
    padding: 16px;
    background-color: $cyan;
}

.titleText{
    color: $white;
    margin-bottom: 8px;
    font-family: 'Marujo';
    font-size:24px;
    line-height: 27px;
}

.input {
    outline: none;
    border: none;
    border: 1px solid rgba(0, 0, 0, 0.12);
    border-radius: 8px;
    font-weight: bold;
    font-size: 16px;
    line-height: 21px;
    color: $nero;
    padding: 20px 20px 20px 60px;
    margin-right: 8px;
    margin-bottom: 10px;


    @include media-breakpoint-up(lg) {
        width: 310px;
        margin-bottom: 0;
    }
}

.inputWrapper
 {
    position: relative;
    &:before {
        content: url("../../../../assets/images/mail.svg");
        position: absolute;
        left: 20px;
        top: 20px;
    }
 }
 
.emailForm{
    display: flex;
    flex-direction: column;
    
    @include media-breakpoint-between(md, md) {
        display: flex;
        flex-direction: column;
    }

    @include media-breakpoint-up(lg) {
        display: flex;
        flex-direction: row;
    }
}

.deleteVideo {
    width: 31px;
    height: 31px;
    border-radius: 8px;
    border: 1px solid $white;
    position: absolute;
    background-color: $light-pink;
    top: 8px;
    right: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 10;
    cursor: pointer;
}

.videoWrapper {
    position: relative;
}

.deleteButton {
    font-size: 18px;
    color: $white;
}

.label-loader {
    display: inline-flex;
    justify-content: center;
    cursor: wait;
    &:before {
      content: '';
      background: url('../../../../assets/images/oval.svg');
      width: 24px;
      height: 24px;
      display: inline-block;
      background-size: cover;
    }
}

.error {
    color: red;
    font-size: 14px;
}