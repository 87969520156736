@import '../../../../scss/lib/mixins';

.InputField {
  flex: 1 1 auto;
  width: 10%;
  max-width: 56px;
  height: 40px;
  font-size: 20px;
  border: 2px solid red;
  color: red;
  box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.12);
  border-radius: 8px;
  margin-right: 6px;
  outline: none;
  text-align: center;

  &.EnteredValue {
    border: 2px solid #467e1b;
    color: #467e1b;
  }

  @include media-breakpoint-up(lg) {
    max-width: 62px;
    height: 52px;
    font-weight: bold;
    font-size: 32px;
    margin-right: 11px;
  }

  &:last-child {
    margin-right: 0;
  }
  &.error {
    color: $red;
    border: 2px solid $red;
  }
}

::placeholder {
  color: red;
}

.multiInputWrapper {
  display: flex;
  flex-direction: row;
}
