@import '../../scss/lib/mixins';

.footer{
    padding-top: 110px;
    padding-bottom: 40px;

}

.footerWraper {
    @include media-breakpoint-up(lg) {
        margin-left: -40px;
        margin-right: -40px;
    }
}

.footerNavigation {
    height: 100px;
    background-color: $footer;
    position: relative;
    display: flex;
    align-items: center;
    padding: 0 20px;

    &:before {
        background-image: url('../../assets/images/half-circle-top-blue.svg');
        background-position: 0 0;
        background-repeat: repeat-x;
        position: absolute;
        left: 0;
        top: -12px;
        width: 100%;
        height: 12px;
        content: '';
        display: block;
    }

    @include media-breakpoint-up(lg) {
        padding: 0 40px;
    }
}

.menuContainer {
    margin-left: 27px;
    flex: 1;

    @include media-breakpoint-up(lg) {
        margin-left: 52px;
    }
}

.linksContainer {
    background-color: $dark-orange;
    padding: 26px 20px 10px 20px;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;

    @include media-breakpoint-up(lg) {
        padding: 24px 40px 28px 40px;
    }
}

.linksList {
    list-style: none;
    padding: 0;
    margin: 0;
    margin-bottom: 13px;

    @include media-breakpoint-up(lg) {
        margin-bottom: 0;
    }

    li {
        margin-bottom: 13px;

        &:last-child {
            margin-bottom: 0;
        }
    }

    a {
        color: $white;
        font-weight: 500;
        font-size: 14px;
        line-height: 19px;
        position: relative;
        padding-left: 30px;
        padding-bottom: 3px;

        i {
            position: absolute;
            left: 0;
            top: 2px;
        }

        span {
            display: inline-block;
            border-bottom: 2px solid transparent;
            padding-bottom: 2px;
        }

        &:hover {
            span {
                border-bottom-color: $white;
            }
        }
    }
}

.socialNetworkTitle {
    color: $white;
    font-weight: bold;
    font-size: 14px;
    line-height: 19px;
}

.socialNetwork {
    padding-top: 12px;
    padding-bottom: 32px;
    margin: 0 -13px;

    a {
        padding: 0 13px;
    }

    @include media-breakpoint-up(lg) {
        padding-bottom: 0;
    }

}

.logo {
    width: 81px;
    height: 52px;

    @include media-breakpoint-up(lg) {
        width: 111px;
        height: 51px;
    }
}

.menuDesktop {
    display: none;
    margin: -10px -23px 0 -23px;
    flex-wrap: wrap;

    @include media-breakpoint-up(lg) {
        display: flex;
    }
}

.menuDesktopLink {
    padding: 10px 23px 0 23px;

    a {
        text-transform: uppercase;
        color: $white;
        font-weight: 900;
        font-size: 16px;
        line-height: 21px;

        &:hover {
            border-bottom: 2px solid $white;
        }
    }
}

.menuDesktopLinkActive {
    a {
        color: $yellow;
        pointer-events: none;
    }
}

.disclaimerLine {
    margin-top: 24px;
    font-weight: bold;
    font-size: 14px;
    line-height: 19px;
    color: $white;
}