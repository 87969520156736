@import "../../../scss/lib/mixins";

.actionButton {
    width: 32px;
    height: 32px;
    border: none;
    border-radius: 50%;
    outline: none;
}

.edit {
    background-color: rgba($color: $cyan, $alpha: 0.1);
    color: $cyan;
}

.delete {
    background-color: rgba($color: $dark-red, $alpha: 0.1);
    color: $dark-red;
}