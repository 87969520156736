@import '../../../scss/lib/mixins';

.modal {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    outline: none;
    background-color: $white;
    border-radius: 24px;
    padding: 24px;
    width: 95%;

    @include media-breakpoint-up(sm) {
        max-width: 600px;
        padding: 24px 46px 32px 31px;
    }
}

.overlay {
    z-index: 200;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba($color: $black, $alpha: 0.5);
}

.bodyOpenClassName {
    overflow: hidden;
    height: calc(100vh - 104px);
}

.modalClose {
    position: absolute;
    right: 24px;
    border: none;
    outline: none;
    background-color: $cyan;
    color: $white;
    border-radius: 8px;
    width: 32px;
    height: 32px;
    display: flex;
    justify-content: center;
    align-items: center;

    &:hover {
        background-color: darken($cyan, 4%);
    }

    i {
        transform: scale(0.8);
    }
}