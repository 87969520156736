@import '../../scss/lib/mixins';

.table {
  width: 100%;
  margin-top: 26px;
}

.columnWithButton {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.numberColumn {
  padding-left: 24px !important;
  width: 70px;
  font-weight: bold;
  font-size: 18px;
  opacity: 0.6;
}

.noOpacity {
  opacity: 1;
}

.nameColumn {
  font-weight: bold;
  font-size: 18px;
  width: 60%;
}

.turnOverColumn {
  color: $dark-red;
  font-weight: 700;
  font-size: 18px;
  text-align: right;

  @include media-breakpoint-up(lg) {
    text-align: left;
  }
}

.totalRow {
  background-color: $white !important;
  color: #439ed5;
  height: 60px;
  pointer-events: none;
}

.totalLabel {
  padding-left: 20px !important;
  font-weight: bold;
  font-size: 21px;

  @include media-breakpoint-up(lg) {
    padding-left: 10px !important;
  }
}

.overviewTableButton {
  width: 40px;
  height: 40px;
  border-radius: 10px;

  &:before {
    left: 9px;
  }
}

.mobileCell {
  padding: 6px 10px;

  span {
    padding-right: 9px;

    &:last-child {
      padding-right: 0;
    }
  }
}

.mobileCellAmount {
  color: $dark-red;
  font-weight: 700;
  margin-top: 4px;
}

.schoolInfo {
  text-align: center;
  padding-top: 28px;
  padding-bottom: 44px;
  color: $white;
  font-weight: 600;
  font-size: 16px;
}

.schoolInfoItem {
  margin-right: 30px;
  display: inline-flex;
  align-items: center;

  span {
    padding-left: 12px;
  }

  &:last-child {
    margin-right: 0;
  }
}

.infoBoxes {
  font-family: 'Marujo';
  display: flex;
  flex-direction: column;
  margin: 0 -10px;
  color: $white;

  @include media-breakpoint-up(lg) {
    flex-direction: row;
  }
}

.infoBox {
  flex-grow: 1;
  margin: 0 10px;
  margin-bottom: 20px;
  padding: 30px;
  text-align: center;
  background-color: #439ed5;
  box-shadow: 0px 15px 10px rgba(0, 0, 0, 0.06);
  border-radius: 12px;
  font-size: 60px;
  line-height: 65px;

  @include media-breakpoint-up(lg) {
    margin-bottom: 0;
    padding: 24px 43px;
  }
}

#text {
  color: #439ed5;
}

.infoBoxTitleRight {
  font-size: 32px;
  color: $dark-red;
}

.infoBoxTitle {
  font-size: 32px;
}

.noResult {
  background-color: $white;
  text-align: center;
  box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.06);
  font-size: 18px;
  padding: 24px;

  @include media-breakpoint-down(sm) {
    img {
      width: 100%;
    }
  }

  @include media-breakpoint-up(lg) {
    padding: 40px 145px;
  }
}

.mobileTotalNumber {
  margin-right: 11px;
}

.illustration {
  position: absolute;
  right: 0;
  width: 104px;
  transform: rotate(-17.97deg);
}

.bottomText {
  margin-top: 16px;
  background-color: $white;
  box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.06);
  padding: 24px;
  font-size: 18px;
}

.bottomTextTitle {
  font-family: Marujo;
  font-size: 24px;
  line-height: 27px;
  display: flex;
  align-items: center;
  margin-bottom: 13px;

  &:before {
    content: url('../../assets/images/Information.svg');
    margin-right: 12px;
  }
}

.buttonKidOverview {
  margin-top: 2rem;
}
