@import "../../scss/lib/mixins";


.title {
    background-color: $green;
    color: $white;
}


.textHeading {
    transform: rotate3d(1, 1, 1, 356deg);
}

.wrapper{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.text {
    font-size: 16px;
    line-height: 22px;
    margin-top: 50px;
    color: $white;
    @include media-breakpoint-up(md) {
        margin-top: 120px;
    }
}