@import "../../../../scss/lib/mixins";


.wrapper {
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: center;
    transform: rotate(-1deg);
    top: -95px;
    left: 0;
    right: 0;

    @include media-breakpoint-up(md) {
        top: 0;
        left: 0;
        right: 0;
    }

}

.leftHeading {
    @include media-breakpoint-up(md) {
        left: -350px;
        top: -105px;
    }
}

.rightHeading {
    @include media-breakpoint-up(md) {
        right: -350px;
        top: -105px;
    }
}

.wrapperWithOptionalSubtitle {
    top: -135px;
}

.title {
    background-color: #439ed5;
    color: $white;
    font-family: 'Marujo';
    padding: 5px 16px;
    font-size: 32px;
    display: inline-block;
    margin-top: -1px;
}

.optionaSubtitle {
    background-color: white;
    color: #439ed5;
    font-family: 'Marujo';
    padding: 5px 16px;
    font-size: 24px;
    display: inline-block;
}

.first {
    background-color: white;
    color: #439ed5
}

.firstCardWrapper {
    @include media-breakpoint-up(md) {
        right: 0;
        left: -500px;
        top: -105px;
    }
}

.withPicture {
    @include media-breakpoint-down(sm) {
        top: -280px;
    }
}