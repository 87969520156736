@import "../../../scss/lib/mixins";


.wrapper {
    padding: 20px;
    background-color: $white;
}

.videoWrapper {
    width: 100%;
    margin-bottom: 20px;
}

.button {
    margin-bottom: 40px;
}

.content {
    margin-bottom: 20px;
}

.title {
    text-align: center;
    font-size: 30px;
    margin-bottom: 20px;
    color: $white;
}

.field {
    font-size: 18;
    margin-bottom: 20px;
    color: $white;
}