@import "../../../scss/lib/mixins";

.list {
    &-wrapper {
        position: relative;
        box-shadow: 0px 30px 30px rgba(0, 0, 0, 0.05);
        border-radius: 24px;
        background-color: $white;
        width: 320px;
        margin-bottom: 70px;
    }

    &-content {
        padding: 32px 24px;
    }

    &-illustration {
        position: absolute;
        height: 100px;
        width: 100px;
        right: 0%;
        top: -25px;
        transform: rotate(-28.89deg);
        @include media-breakpoint-up(lg) { 
            left: 0%;
            top: -80px;
        }
    }
}

.list-image {
    position: relative;
    z-index:100;
    margin-bottom: 100px;
    margin-left: auto;
    margin-right: auto;
}

.order-list {
    list-style: none;
    padding-left: 0;
    padding-top: 32px;
    padding-bottom: 24px;

    a {
        color: $cyan;
        font-size: 16px;
        font-weight: 700;
        position: relative;
        line-height: 22px;

        &:hover > span {
            text-decoration: underline;
            color: $cyan;
        }

        &:before {
            content: url("../../../assets/images/place-board-blue.svg");
            position: absolute;
        }

        &:hover {
            margin-left: 16px;
        }
    }

    li {
        margin-bottom: 8px;
    }
}

.link-text {
    color: $light-pink;
    font-size: 16px;
    font-weight: bold;
    padding-left: 14px;
}

.circle-wrapper {
  width: 22px;
  height: 22px;
  background-color: $cyan;
  font-family: 'Marujo';
  font-size: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 8px;
  border-radius: 50%;
  color: $white;
  flex-shrink: 0;

  &-icon {
    position: relative;

    &:after {
        content: url("../../../assets/images/check.svg");
        position: absolute;
    }
  }


  &-1 {
    background-color: $green;
  }

  &-2 {
    background-color: $magenta;
  }

  &-3 {
    background-color: $purple;
  }
}

.button-link {
    display: flex;
    align-items: center;

    &:hover {
        .link-text{
            color: $light-pink;
        }
    }
}

.icon-text {
    padding-left: 34px;
    &:hover{
        color: $cyan;
    }
}

.word{
    max-width: 230px;
}

.image {
    width: 100%;
    height: auto;
    max-width: 400px;
}