@import '../../../scss/lib/mixins';

.wrapper {
    border-radius: 16px;
    background-color: $white;
    padding: 2px;
    height: 64px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    z-index: 2;
}


.menu-list {
    list-style: none;
    flex-direction: row;
    display: flex;
    align-items: center;
    padding: 0;
    margin: 0;
    height: 100%;
}

.mobileWrapper {
    display:flex;
    align-items: center;
    justify-content: center;
}