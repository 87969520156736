@import "../../../scss/lib/mixins";


.wrapper {
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: start;
    transform: rotate(-1deg);
    left: 0;
    right: 0;
    top: -30px;
    padding: 0 16px;
    
}

.withPicture {
    @include media-breakpoint-down(sm) {
        top: -280px;
    }
    top: -50px;
}

.wrapperWithOptionalSubtitle {
    top: -70px;
}

.title {
    background-color: $dark-orange;
    color: white;
    font-family: 'Marujo';
    padding: 5px 16px;
    font-size: 32px;
    display: inline-block;
    margin-top: -1px;
    box-shadow: 0px 15px 10px 0px rgba(0, 0, 0, 0.06);

}

.optionaSubtitle {
    background-color: white;
    color: $dark-orange;
    font-family: 'Marujo';
    padding: 5px 16px;
    font-size: 24px;
    display: inline-block;
    margin-left: 1rem;
}


