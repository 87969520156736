@import "../../../scss/lib/mixins";

.typeColumn {
    font-weight: bold;
    font-size: 18px;
    margin-bottom: 2px;

    @include media-breakpoint-up(lg) {
        margin-bottom: 0;
    }
}

.tableHead {
    padding-bottom: 6px !important;
    font-weight: 600 !important;
}

.table {
    width: 100%;
    margin-top: 26px;
    color: $dark;

    td {
        height: 93px;

        @include media-breakpoint-up(lg) {
            height: 72px;
        }
    }
}

.label {
    text-align: center;
    font-size: 28px;
    color: $white;
    margin-right: 30px;
}

.input {
    border-radius: 8px;
    padding: 10px 20px;
    font-size: 16px;
    border: 1px solid $black;
    margin-right: 20px;
}

.mobileRow {
    display: flex;
    align-items: center;
    margin-left: 10px;
    margin-right: 10px;

}

.tableRow {
    cursor: pointer;
}

.button {
    margin-left: 20px;
}