.wrapper {
    position: relative;
    
    &:before {
    
        background-image: url('../../assets/images/dashed-line.svg');
        background-position: 0 0;
        background-repeat: repeat-y;
        position: absolute;
        bottom: -12px;
        top: 500px;
        left: 50%;
        margin-left: -5px;
        width: 10px;
        content: '';
        display: block;
    }
}