@import "../../../scss/lib/mixins";

.heading {
    font-family: 'Marujo';
    &-padding {
    padding: 0 10px;
    }

    &-wrapper {
        display: inline-block;
        transform: rotate(-2deg);
        box-shadow: 0px 15px 10px rgba(0, 0, 0, 0.06);
    }

    &-wrapper-header {
        display: inline-block;
        transform: rotate(-2deg);
    }

    &-0 {
        font-size:40px;

        @include media-breakpoint-up(md) {
            font-size:64px;
        }

        @include media-breakpoint-up(lg) {
            font-size:80px;
        }
    }

    &-1 {
        font-size:32px;

        @include media-breakpoint-up(md) {
            font-size:48px;
        }

        @include media-breakpoint-up(lg) {
            font-size:56px;
        }

    }

    &-2 {
        font-size:22px;

        @include media-breakpoint-up(md) {
            font-size:24px;
        }

        @include media-breakpoint-up(lg) {
            font-size:32px;
            line-height: 45px;
        }
    }

    &-3 {
        font-size:21px;
        
        @include media-breakpoint-up(md) {
            font-size:21px;
        }

        @include media-breakpoint-up(lg) {
            font-size:24px;
            line-height: 27px;
        }
    }

    &-4 {
        font-size:18px;
        line-height: 21px;
    }

    &-5 {
        font-size:32px;
        line-height: 34px;
        
        @include media-breakpoint-down(md) {
            font-size:21px;
            line-height: 30px;
        }

        @include media-breakpoint-down(xs) {
            font-size:24px;
            line-height: 22px;
        }
    }
}

.bg-darkGreen {
    background-color: #467E1B;
}

.isHover {
    color: $black;
}

.lightPink {
    background-color: #E74697 !important;
}

.bg-dark-orange {
    background-color: #DD4C18 !important;
}