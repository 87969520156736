@import '../../../scss/lib/mixins';

.tableCollapse {
    border-collapse: separate;
    border-spacing: 0 4px;
}

.header {
    color: $white;
    
    th {
        padding: 10px 10px 12px 10px;
    }
}

.body {
    tr {
        background-color: $white;
        box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.06)
    }

    td {
        padding: 10px;
    }
}

.tableHover {
    tr:hover td {
        color: $dark-red;
        opacity: 1;
    }
}