@import '../../../../scss/lib/mixins';

.title {
    font-size: 10px;
    line-height: 13px;
}

.subtitle {
    font-size: 14px;
    line-height: 19px;
    text-align: center;
}

.menu-item {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.list-item {
    height: 100%;
    border-radius: 16px;
    display: flex;
    align-items: center;
    color: rgba(0, 0, 0, 0.6);
    padding: 0 15px;

    &:first-child {
        padding: 21px 24px;
        background-color: #439ed5;
        color: $white;
    }

    @include media-breakpoint-up(xl) {
        padding: 0 24px;
        &:first-child {
            padding: 21px 32px;
            background-color: #439ed5;
            color: $white;
        }
    }
}