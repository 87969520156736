body {
    display: flex;
    flex-direction: column;
    position: relative;
    background: linear-gradient(179.78deg, #DD4C18 0.21%, rgba(0, 159, 218, 0) 99.83%), linear-gradient(359.61deg, #FED100 0.32%, rgba(254, 209, 0, 0) 99.68%), #FFFFFF;
    overflow-x: hidden;
    font-smooth: always;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    &:before {
        background-image: url('../assets/images/smileys.svg');
        background-position: 0 0;
        background-repeat: repeat;
        position: absolute;
        left: 0;
        width: 100%;
        height: 100%;
        content: '';
        opacity: 0.3;
        display: block;
        z-index: -10;
    }
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: 'Marujo';
    margin-bottom: 0 !important;
}

@include media-breakpoint-up(lg) {
    .position-lg-static {
        position: static !important;
    }
}

.isLoaded {
    display:none;
}

main {
    margin-top: 130px;

    @include media-breakpoint-up(lg) {
        margin-top: 180px;
    }
}

.admin-login-wrapper {
    background-color: white;
    padding: 20px 30px;
    border-radius: 12px;
    max-width: 500;
    display: flex;
    justify-content: center;
}

.admin-login-input {
    margin-bottom: 8px;
    input {
        padding: 5px 10px;
        width: 100%;
    }
}

.admin-login-button {
    display: inline-flex;
    align-items: center;
    background-color: $red;
    padding: 10px 15px;
    border: none;
    color: $white;
    text-align: center;
    text-decoration: none;
    cursor: pointer;
    border-radius: 12px;
    font-size: 16px;
    font-weight: bold;
    line-height: 21px;
    justify-content: center;
    z-index: 1;
    width: 100%;
}

.admin-login-validation {
    color: $red;
    padding-bottom: 12px;
    font-size: 12px;
}

.validation-summary-errors {
    font-size: 12px;
    color: $red;
    margin-top: 20px;
    ul {
        list-style: none;
        padding-left: 0;
    }
}

.admin-login-content{
    width: 100%;
    max-width: 300px;
}

.admin-login-title {
    font-size: 30;
    margin-bottom: 10px;
}

.teacher-columns {
    padding-top: 60px;
    margin-left: -16px;
    margin-right: -16px;

    > div {
        padding-left: 16px;
        padding-right: 16px;
    }

    & :first-child {
        z-index: 2;
    }
    & :nth-child(2){
        z-index: 1;
    }
}

.video-sarousel {
    .slick-list {
        overflow: visible;
    }

    .slick-track {
        display: flex;
    }
}