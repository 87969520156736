@import "../../../../scss/lib/mixins";


.Wrapper {
    margin-top: 60px;
}

.FormControlMargin {
    margin-top: 24px;
}

.Description {
    margin-top: 8px;
    font-size: 12px;
    font-weight: normal;
    line-height: 16px;
    color: #333333;
    opacity: 0.5;
}

.Button {
    margin-top: 24px;
    
    @include media-breakpoint-up(lg) {
        margin-top: 70px;
    }
}


