@import "../../../../scss/lib/mixins";

.question {
    &-wrapper{
        box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.06);
        border-radius: 8px;
        margin-bottom: 4px;
        cursor: pointer;
        background-color: $white;
    }

    &-content {
        padding: 12px 0 12px 16px;
        position: relative;

        @include media-breakpoint-up(lg) {
            padding: 24px 0 24px 24px;
        }

        &:after {
            content: url("../../../../assets/images/arrow-top.svg");
            position: absolute;
            right: 30px;
            top: 0;
            display: flex;
            height: 100%;
            align-items: center;
        }
    }

    &-content-arrow-rotate {
        padding: 12px 0 12px 16px;
        position: relative;

        @include media-breakpoint-up(lg) {
            padding: 24px 0 24px 24px;
        }

        &:after {
            content: url("../../../../assets/images/arrow-top.svg");
            position: absolute;
            right: 30px;
            top: 25px;
            transform: rotate(180deg);

            @include media-breakpoint-up(lg) {
               top: 30px
            }
        }
    }


    &-icon-rotated {
        transform: rotate(180deg);
    }

    &-heading {
        font-size: 18px;
        font-weight: bold;
        padding-right: 60px;
    }

    &-paragraph {
        padding: 24px 35px 0 0;

        a {
            text-decoration: underline;

            &:hover,
            &:focus,
            &:active {
                color: $light-pink;
            }
        }
    }
}


.button-link {
    display: flex;
    align-items: center;

    &:hover {
        .link-text{
            color: $light-pink;
        }
    }
}


.link-text {
    color: $light-pink;
    font-size: 16px;
    font-weight: bold;
    padding-left: 14px;
}