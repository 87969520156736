@import '../../scss/lib/mixins';

.header {
  display: flex;
  justify-content: center;

  .title {
    position: relative;
    font-size: 18px;
    line-height: 21px;
    left: 0;
    padding: 8px 16px;
    box-shadow: 0px 15px 10px rgba(0, 0, 0, 0.06);
    background-color: white;
    color: $dark-orange;
    margin-bottom: 12px;
  }
}

.iconDropDown {
  display: inline-block;
  margin-right: 11px;
  width: 24px;
  height: 24px;
  margin-top: -4px;
}

.mobileDropDown {
  margin-bottom: 30px;
  display: block;

  @include media-breakpoint-up(lg) {
    display: none;
  }
}

.desktopNavigation {
  display: none;
  justify-content: center;

  @include media-breakpoint-up(lg) {
    display: flex;
    width: 100%;
    max-width: 500px;
    margin: auto;
    flex-direction: row;
    justify-content: center;
  }

  .link {
    display: flex;
    margin: 0 7px;
    background-color: $dark-red;
    box-shadow: 0px 15px 10px rgba(0, 0, 0, 0.06);
    padding: 16px 24px;
    font-weight: 900;
    font-size: 16px;
    line-height: 22px;
    color: $white;
    border-radius: 12px;

    &:hover {
      background-color: darken($dark-red, 4%);
    }

    img {
      margin-right: 21px;
      width: 24px;
      height: 24px;
      align-self: center;
    }
  }

  .active {
    background-color: darken(#439ed5, 4%);
  }
}
