@import "../../../scss/lib/mixins";

.overviewHeading {
    display: flex;
    justify-content: center;
    font-family: 'Marujo';
    position: relative;
    padding-top: 26px;
    margin-top: 40px;
}

.overviewHeadingBody {
    min-width: 250px;
    font-size: 42px;
    line-height: 42px;
    background-color: $white;
    color: $dark-orange;
    text-align: center;
    padding: 10px 24px;
    overflow: hidden;
    text-overflow: ellipsis;
    
    @include media-breakpoint-up(lg) {
        font-size: 56px;
        line-height: 56px;
    }
}

.overviewHeadingTitle {
    min-width: 228px;
    position: absolute;
    background-color: #439ed5;
    color: $white;
    font-size: 21px;
    line-height: 24px;
    padding: 8px 24px;
    text-align: center;
    transform: rotate3d(1, 1, 1, 356deg);
    top: -5px;
    
}