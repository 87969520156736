@import "../../scss/lib/mixins";

.componentWrapper {
    margin-top: 60px;
}

.table {
    width: 100%;
    margin-top: 26px;
}

.totalTable {
    width: 100%;
    margin-top: 32px;
}

.numberColumn {
    padding-left: 24px !important;
    width: 70px;
    font-size: 18px;
    opacity: 0.6;
}

.noOpacity {
    opacity: 1;
}

.totalNumberColumn {
    font-size: 21px;
}

.nameColumn {
    font-weight: bold;
    font-size: 18px;
    width: 330px;
}

.turnOverColumn {
    color: $dark-red;
    font-weight: 700;
    font-size: 18px;
    text-align: right;

    @include media-breakpoint-up(lg) {
        text-align: left;
        width: 300px;
    }
}

.inputSales {
    @include media-breakpoint-up(lg) {
        margin-left: -8px;
    }

    input {
        color: $cyan !important;
        font-weight: 700 !important;
        font-size: 18px !important;
    }
}

.totalRow {
    background-color: $white !important;
    height: 72px;
    pointer-events: none;
}

.totalLabel {
    padding-left: 20px !important;
    font-weight: bold;
    font-size: 21px;

    @include media-breakpoint-up(lg) {
        padding-left: 10px !important;
    }
}

.mobileCell {
    display: flex;
    padding: 6px 10px;

    span {
        padding-right: 9px;

        &:last-child {
            padding-right: 0;
        }
    }
}

.mobileCellAmount {
    color: $dark-red;
    font-weight: 700;
    margin-top: 4px;
}

.mobileCellMargin {
    margin-top: 4px;
}

.generalRow {
    height: 72px;
}

.blobs {
    width:417px;
    height:375px;
    z-index: -10;
    top: -65px;
    right: -147px;

    @include media-breakpoint-up(xl) {
        top: -52px;
        left: 397px;
    }

    @include media-breakpoint-between(lg, lg) {
        top: -52px;
        left: 313px;
    }
}

.tableHead {
    padding-bottom: 6px !important;
    font-weight: 600 !important;
}

.typeWithIcon {
    display: flex;
    align-items: center;

    &:before {
        content: url('../../assets/images/pc-black.svg');
        display: block;
        margin-right: 17px;
        margin-top: 3px;
    }
}

.typePaperWithIcon {
    display: flex;
    align-items: center;

    &:before {
        content: '';
        display: block;
        margin-right: 17px;
        margin-top: 3px;
        margin-left: 5px;
        background-image: url('../../assets/images/paper.svg');
        width: 20px;
        height: 27px;
        background-size: cover;
    }
}

.deleteButton {
    font-weight: bold;
    font-size: 18px;
}

.editButton {
    font-size: 18px;
}

.actionButtons {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    @include media-breakpoint-up(lg) {
        display: block;
    }
    
    button + button {
        margin-top: 6px;

        @include media-breakpoint-up(lg) {
            margin-top: 0;
            margin-left: 16px;
        }
    }
}

.actionButtonColumn {
    width: 141px;
}

.editColumn {
    padding: 6px 0 !important;
}

.columnAmount {
    width: 150px;
}

.submitButton {
    height: 56px;
}

.mobileFieldMargin {
    margin: 20px 0;
}

.editInputBorder {
    input {
        border: 1px solid $gray-600 !important;
        box-shadow: 0px 0px 3px rgba($color: #000000, $alpha: 0.12) !important;
    }
}