@import '../../scss/lib/mixins';

.customContainer {
    display: flex;
    justify-content: center;

    scroll-behavior: smooth;
    width: 100%;
    // margin-right: 16px;
    
    
    position: relative;
    padding-bottom: 25px;
    padding-top: 25px;
    
    
    @include media-breakpoint-up(md) {
        margin-right: auto;
        margin-left: auto;
        padding-bottom: 60px;
        padding-top: 165px;
    }
    @include media-breakpoint-up(lg) {
        padding-bottom: 100px;
        padding-top: 165px;
        margin-right: 0;
        margin-left: 0;
    }

}

.grid {
    scroll-margin-top: 2ex;
    display: flex;
    flex-direction: column;

    @include media-breakpoint-up(md) {
        flex-direction: row;
        // display: grid;
        // grid-template-columns: 1fr 1fr;
        // margin: 0 32px;
    }
    @include media-breakpoint-up(lg) {
        display: flex;
    }



}

.left {
    display: flex;
    flex-direction: column;
    justify-content: center;
    scroll-behavior: smooth;
    width: 450px;
    padding: 32px;
    @include media-breakpoint-up(md) {
        padding-left:0px;
        width: 400px;
        position: sticky;
        top: 100px;
        // height: calc(100vh - 30vh - 1px);
        height: 100%;
        border-radius: 24px;
        padding-top: 64px;
        justify-content: flex-start;
        // padding-left: 0;
    }


    @include media-breakpoint-up(lg) {
    }
    }

.right {
    display: flex;
    flex-direction: column;
    scroll-behavior: smooth;
    padding: 2.5rem 2.5rem;
    align-items: center;

    @include media-breakpoint-up(md) {
        padding: 0;
    }
}

.stepCard {
    margin-top: 3rem;
}

.stepCard:first-of-type {
    margin-top: -2rem;
    @include media-breakpoint-up(md) {
        margin-top: -4rem;
    }

}
.stepCard:first-of-type.noImage {
    margin-top: 2rem;
    @include media-breakpoint-up(md) {
        margin-top: -2rem;
    }
}

.noImage {
    margin-top: 7rem;
}


.background {
    background-color: $blue;
    left: -16px;
    z-index: -1;
    height: 100%;
    position: absolute;
    width: calc(100vw);
    top: 0;
    margin-left: 16px;
    
    @include media-breakpoint-up(md) {
        border-radius: 30px;
        margin-left: 0;
        // height: calc(100vh - 100px - 8rem);
        // width: calc(100vw - 8rem);
        left: -2rem;
        width: calc(200% + 16px);
        
    }
    
    @include media-breakpoint-up(lg) {
        left: -4rem;
        width: calc(860px + 1rem);
    }

}


.illustrationTop {
    position: absolute;
    width: 6rem;
    height: 6rem;
    visibility: hidden;

    @include media-breakpoint-up(md) {
        visibility: visible;
        top: -3rem;
        left: -7rem
    }
    @include media-breakpoint-up(lg) {
        left: -10rem;
    }
}

.illustrationBottom {
    width: 6rem;
    height: 6rem;
    position: absolute;
    visibility: hidden;

    @include media-breakpoint-up(md) {
        visibility: visible;
        bottom: -7rem;
        left: -7rem
    }
    @include media-breakpoint-up(lg) {
        left: -10rem;
        bottom: -6rem;
    }
}

.illustrationRight {
    width: 6rem;
    height: 6rem;
    position: absolute;
    visibility: hidden;

    @include media-breakpoint-up(md) {
        visibility: visible;
        right: -26rem;
        top: 12rem;
    }

    @include media-breakpoint-up(lg) {

        right: -32rem;
    }
}

.linkArrow {
    color: white;
    display: flex;
    gap: 12px;
    font-weight: 700;

    &:before {
        content: url("../../assets/images/arrow.svg");
        position: relative;
        left: 0;
    }

    &:hover {
        transition: ease 0.2s;
        transform: translateX(0.5rem);
    }
}

.dateList {
    list-style: none;
    padding-left: 0;
    margin-top: 1rem;
    gap: 0.5rem;
    display: flex;
    flex-direction: column;
    ;
}

.description {
    color: white;
    padding-left: 32px;
    @include media-breakpoint-up(md) {
        padding-left: 0;
    }
}

.card-paragraph {
    padding-top: 2.4rem;
    flex-grow: 1;
    font-weight: 700;
    font-size: 16px;

    a {
        text-decoration: underline;

        &:hover,
        &:focus,
        &:active {
            color: $light-red;
        }
    }
}

.descriptionButton {
    margin-top: 2rem;
}

