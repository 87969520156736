@import '../../scss/lib/mixins';

.titleWrapper {
    display: flex;
    justify-content: center;

    @include media-breakpoint-up(lg) {
        display: block;
    }
}

.title {
    position: relative;
    font-size: 32px;
    line-height: 37px;
    min-width: auto;
    left: 0;
    box-shadow: 0px 15px 10px rgba(0, 0, 0, 0.06);

    @include media-breakpoint-up(lg) {
        font-size: 56px;
        line-height: 64px;
        padding: 8px;
    }
}

.text {
    padding-top: 26px;
    font-weight: 600;
    font-size: 18px;
    line-height: 25px;
    text-align: center;
    color: $white;

    @include media-breakpoint-up(lg) {
        text-align: left;
    }
}

.slide {
    padding: 0 4px;

    @include media-breakpoint-up(lg) {
        padding: 0 16px;
        max-width: 600px;
    }
}

.imageWrapper {
    background-color: $white;
    padding: 8px 8px 24px 8px;
    border-radius: 12px;
    position: relative;
    cursor: pointer;

    @include media-breakpoint-up(lg) {
        padding: 16px 16px 46px 16px;
        border-radius: 24px;
    }
}

.videoTitle {
    font-family: Marujo;
    background: #439ed5;
    box-shadow: 0px 15px 10px rgba($color: $black, $alpha: 0.06);
    transform: rotate(-1deg);
    color: $white;
    font-size: 21px;
    line-height: 24px;
    padding: 4px;
    position: absolute;
    text-align: center;
    max-width: 187px;
    bottom: -17px;
    left: auto;

    @include media-breakpoint-up(lg) {
        font-size: 24px;
        line-height: 27px;
        max-width: 371px;
        left: 45px;
        padding: 8px;
    }
}

.slider {
    padding-left: 16px;

    @media screen and (min-width: 481px) {
        padding-left: 0;
    }
}

.videoWrapper {
    background-color: $white;
    border-radius: 12px;
    padding: 16px 12px 32px 12px;
    margin-top: 23px;
    margin-bottom: 27px;
    position: relative;

    @include media-breakpoint-up(lg) {
        padding: 32px 32px 53px 32px;
        margin-top: 63px;
        margin-bottom: 52px;
        margin-left: 30px;
        margin-right: 30px;
    }
}

.videoTitlePosition {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: -13px;

    @include media-breakpoint-up(lg) {
        bottom: -18px;
    }
}

.selectedVideoTitle {
    background-color: $dark-orange;
    color: white;
    padding: 4px 8px;
    font-family: Marujo;
    font-size: 21px;
    line-height: 24px;
    box-shadow: 0px 15px 10px rgba(0, 0, 0, 0.06);
    transform: rotate(-1deg);

    @include media-breakpoint-up(lg) {
        font-size: 24px;
        line-height: 27px;
        padding: 8px;
    }
}

.selectedLabel {
    position: absolute;
    background-color: white;
    color: #439ed5;
    font-family: Marujo;
    font-size: 18px;
    line-height: 21px;
    transform: rotate(-1deg);
    padding: 4px 8px;
    z-index: 1;
    left: 13px;
    bottom: 12px;
    display: none;

    @include media-breakpoint-up(lg) {
        padding: 8px;
        left: 48px;
        bottom: 20px;
    }
}

.playWrapper {
    position: relative;

    @include media-breakpoint-up(lg) {
        position: static;
    }
}

.playButton {
    width: 50px;
    height: 50px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: linear-gradient(179.78deg, #DD4C18 0.21%, rgba(0, 159, 218, 0) 99.83%), linear-gradient(359.61deg, #FED100 0.32%, rgba(254, 209, 0, 0) 99.68%), #FFFFFF;
    border: 5px solid $white;
    box-shadow: 0px 0px 40px rgba(0, 0, 0, 0.13), inset 0px 0px 12px rgba(0, 0, 0, 0.25);
    color: $white;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;

    i {
        display: block;
        transform: scale(0.9);
        margin-left: 4px;
    }

    @include media-breakpoint-up(lg) {
        transform: none;
        top: auto;
        left: auto;
        right: 48px;
        bottom: -20px;
    }
}

.active {
    cursor: default;
    pointer-events: none;
    
    &:before {
        content: '';
        position: absolute;
        top: 0; 
        right: 0; 
        bottom: 0; 
        left: 0;
        z-index: -1;
        margin: -3px; /* !importanté */
        border-radius: inherit; /* !importanté */
        background: linear-gradient(90deg, #DD4C18 0.21%, rgba(0, 159, 218, 0) 99.83%), linear-gradient(270deg, #FED100 0.32%, rgba(254, 209, 0, 0) 99.68%), #FFFFFF;
      }

      .selectedLabel {
          display: block;
      }

      .playButton {
          display: none;
      }
}

.buttons {
    display: flex;
    justify-content: center;
    margin-top: 60px;
}

.button {
    width: 50px;
    height: 50px;
    background-color: #439ed5;
    border: 5px solid $white;
    margin: 0 6px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    color: $white;
    cursor: pointer;
    font-size: 11px;

    &:hover {
        background-color: darken(#439ed5, 4%);
    }
}

.illustration {
    position: absolute;
    transform: rotate(20.93deg);
    width: 75px;
    height: 98px;
    right: 50px;
    display: none;

    @include media-breakpoint-up(lg) { 
        display: block;
    }
}

.illustrationBottom {
    position: absolute;
    display: none;
    transform: rotate(-17.97deg);
    width: 80px;
    height: 127px;
    bottom: 25px;
    left: -100px;

    @include media-breakpoint-up(lg) { 
        display: block;
    }
}

.blob {
    z-index: -1;
    top: -153px;
    transform: scale(0.5);
    right: -266px;

    @include media-breakpoint-up(lg) { 
        transform: none;
        top: -38px;
        right: -151px;
    }
}

.onlyOne {
    width: 70% !important;
}