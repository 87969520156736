@import "../../scss/lib/mixins";

.card-wrapper {
    margin-top: -30px;
    margin-bottom: 46px;


    @include media-breakpoint-up(lg) {
        margin-top: -96px;
    }

    @include media-breakpoint-up(md) {
        &:nth-child(-n + 2) {
            margin-top: -96px;
        }
    }

}
