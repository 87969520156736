@import "../../../scss/lib/mixins";

.button {
    width: 100%;
    justify-content: left;
    min-height: 48px;
    font-weight: 900;

    @include media-breakpoint-up(lg) {
        height: 72px;
    }
}

.linkWrapper {
    & + & {
        margin-top: 8px;

        @include media-breakpoint-up(lg) {
            margin-top: 0;
            margin-left: 24px;
        }
    }
}