@import '../../scss/lib/mixins';

.myShop {
  margin-top: 40px;
  margin-bottom: 80px;
}

.text {
  font-weight: 600;
  font-size: 18px;
  line-height: 25px;
  color: $white;
}

.inputContainer {
  margin-top: 24px;
  display: block;
  position: relative;

  @include media-breakpoint-up(md) {
    margin-left: -8px;
  }

  @include media-breakpoint-up(lg) {
    display: flex;
    margin-bottom: 20px;
  }
}

.inputColumn {
  flex-grow: 1;
  padding-bottom: 16px;

  @include media-breakpoint-up(md) {
    margin-left: -8px;
  }

  @include media-breakpoint-up(lg) {
    padding: 0 7px;
  }
}

.buttonColumn {
  padding-bottom: 16px;

  @include media-breakpoint-up(lg) {
    padding: 0 7px;
  }
}

.button {
  width: 100%;
  height: 56px;
  justify-content: left;

  @include media-breakpoint-up(lg) {
    width: auto;
    height: 100%;
    justify-content: center;
  }
}

.urlInput {
  border: 1px solid $gray-600;
  box-shadow: 0px 0px 3px rgba($color: $black, $alpha: 0.12);
  word-break: break-all;
  padding: 18px 19px 17px 19px;
  background-color: $white;
  border-radius: 8px;
  color: rgba($color: $nero, $alpha: 0.5);
  font-weight: bold;
  font-size: 16px;
  line-height: 21px;
}

.videosContainer {
  padding-top: 43px;
  padding-bottom: 110px;
}

.videosTitle {
  margin-bottom: 44px;

  @include media-breakpoint-up(lg) {
    margin-bottom: 64px;
  }
}

.heading {
  font-size: 22px;
  line-height: 25px;
  min-width: auto;
  padding: 4px 8px 4px 8px;
  box-shadow: 0px 15px 10px rgba($color: $black, $alpha: 0.06);

  @include media-breakpoint-up(lg) {
    font-size: 32px;
    line-height: 45px;
  }
}

.videoRow {
  position: relative;
  margin: -12px 0;

  @include media-breakpoint-up(lg) {
    display: flex;
    margin: 0 -13px;
  }
}

.videoColumn {
  padding: 12px 0;

  @include media-breakpoint-up(lg) {
    padding: 0 13px;
    flex: 1 1 0px;
  }
}

.blobRotate {
  transform: rotate(-12.04deg);
  position: relative;
}

.blob {
  left: -67px;
  top: -37px;
  transform: scale(0.8);
  position: absolute;

  @include media-breakpoint-up(lg) {
    left: 52%;
    transform: translateX(-50%);
    top: -40px;
  }
}

.illustration {
  position: absolute;
  transform: rotate(-28.89deg);
  right: 0;
  width: 100px;
  bottom: -171px;

  @include media-breakpoint-up(lg) {
    bottom: -45px;
  }

  img {
    width: 100%;
  }
}

.link {
  text-decoration: none !important;
}

.headingPadding {
  padding: 8px 16px;
}
