@import '../../../scss/lib/mixins';


.wrapper {
    border-radius: 16px;
    background-color: #F5F5F5;
    position: relative;
    margin-bottom: 43px;
    cursor: pointer;
    transition: transform 250ms;
    transition-timing-function: ease-in-out;

    &:hover  {
        transform: translateX(10px);
        box-shadow: 0px 0px 19px 0px rgba(122,122,122,0.41);;
        .title {
            color: $blue;
        }

        .icon {
            transform: scale(1.2);
        }
        
        .circleWrapper {
            transform: translateX(-10px);
        }
    }
}

.circleWrapper {
    position: absolute;
    background-color: #FFFFFF;
    height: 50px;
    width: 50px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    left:0;
    right:0;
    margin-left: auto;
    margin-right: auto;
    top: -25px;
    
    @include media-breakpoint-up(md) {
        top:0;
        bottom:0;
        margin-top: auto;
        margin-bottom: auto;
        margin-left: 0;
        margin-right: 0;
        left: -25px;
        height: 72px;
        width: 72px;
    }
}

.circle {
    border-radius: 50%;
    height: 40px;
    width: 40px;
    background-color: white;
    position: relative;
    background: linear-gradient($red, $yellow);

    @include media-breakpoint-up(md) {
        height: 66px;
        width: 66px;
    }
}
.icon {
    position: absolute;
    left: 8px;
    top: 7px;
    height: 24px;
    width: 24px;
    
    @include media-breakpoint-up(md) {
        height: 40px;
        width: 40px;
        left: 12px;
        top: 10px;
    }
}

.title {
    font-size: 16px;
    line-height: 21px;
    color:$black;
    font-weight: bold;
    padding: 24px 20px 0 20px;
    text-align: center;

    @include media-breakpoint-up(md) {
        text-align: start;
        padding: 22px 30px 0 72px;
    }
}

.subtitle {
    font-size: 16px;
    line-height: 21px;
    padding: 4px 34px 11px 34px;
    text-align: center;
    color: $black;
    
    @include media-breakpoint-up(md) {
        text-align: start;
        padding: 4px 30px 21px 72px;
    }
}
