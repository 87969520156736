.react-select__control {
    height: 56px;
    border: 1px solid $gray-600;
    box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.12);
    border-radius: 8px !important;
    border-color: $gray-600 !important;
    &:focus {
        border-color: red !important;
    }
}

.react-select__value-container {
    padding-left: 16px !important;
}

.react-select__indicator {
    padding-right: 24px !important;
}

.react-select__option {
    position: relative;
    &:last-child {
        border-bottom: none;
    }

    &:after {
        content : "";
        position: absolute;
        left: 25px;
        bottom: 0;
        height: 1px;
        width: 90%;
        border-bottom: 1px solid #000000;
    }

    &:hover {
        cursor: pointer;
        background-color: #EFF5FF;
    }
}

.basic-single {
    z-index: 10;
}