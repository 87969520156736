@import "../../scss/lib/mixins";

.sideblock-paragraph {
    &-wrapper {
        padding-top: 34px;
        padding-bottom: 48px; 

        @include media-breakpoint-up(lg) {
            padding-top: 42px;
            padding-bottom: 71px; 
        }
    }

    &-paragraph {
        padding-bottom: 32px;
        padding-top: 20px;
        font-weight: normal;
        font-size: 16px;
        line-height: 140%;

        a {
            text-decoration: underline;

            &:hover,
            &:focus,
            &:active {
                color: $dark-red;
            }
        }

        @include media-breakpoint-up(lg) {
            padding-top: 28px;
        }
    }

    &-list {
        display: flex;
        height: 100%;
        align-items: center;
    }

    &-list-image {
        display: flex;
        height: 100%;
        margin-top: 50px;
    }
}

.blob {
    bottom: 0;
    right: 20px;

    @include media-breakpoint-up(lg) {
        top: 5px;
        right: 160px;
    }

    @include media-breakpoint-between(md, md) {
        bottom: 0;
    }
}

.lightRedBackground {
    background-color: $light-red;
}