@import '../../scss/lib/mixins';

.component {
    position: absolute;
    z-index: 200;
    width: 100%;
    pointer-events: none;
}

.navigation {
    border-radius: 20px;
    margin-top: 18px;
    margin-bottom: 18px;
    height: calc(100vh - 36px);
    display: flex;
    flex-direction: column;
    overflow: hidden;

    @include media-breakpoint-up(lg) {
        margin-left: -40px;
        margin-right: -40px;
        height: auto;
        overflow: visible;
        pointer-events: auto;
    }
}

.mobileCollapse {
    flex: 1;
    background-color: $dark-red;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
    transform: translateY(-100%);
    transition: .8s transform;
    z-index: 1;
    overflow-y: auto;
    pointer-events: auto;
}

.menuOpen {
    .mobileCollapse {
        transform: translateY(0);
    }
}

.logo {
    width: 101px;
    height: 47px;

    @include media-breakpoint-up(lg) {
        width: 127px;
        height: 59px;
    }
}

.mobileNavigation {
    box-shadow: 0px 15px 30px rgba($color: #8A7366, $alpha: 0.08);
    display: flex;
    justify-content: space-between;
    height: 80px;
    padding: 13px 20px 13px 20px;
    position: relative;
    background: $dark-red;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    flex-shrink: 0;
    z-index: 2;
    pointer-events: auto;
}

.mobileLinks {
    display: inline-flex;
    height: 100%;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
    color: $white;

    span {
        font-size: 14px;
        line-height: 19px;
    }

    i {
        font-size: 17px;
        margin-bottom: 5px;
    }

    & + & {
        margin-left: 33px;
    }
}

.tabsNavigation {
    margin-top: 50px;
}

.tabs {
    margin: 0 12px;

    @include media-breakpoint-up(lg) {
        margin: 0;
        flex: 1;
        margin-left: 180px;
    }
}

.tab {
    padding: 10px 20px;
    font-size: 14px;
    font-weight: 900;
    line-height: 19px;
    text-transform: uppercase;
    color: rgba($color: $white, $alpha: 0.4);

    span {
        display: inline-block;
        border-bottom: 2px solid transparent;
    }

    &:hover {
        span {
            border-bottom-color: $white;
        }
    }

    & + & {
        margin-left: 10px;
    }

    @include media-breakpoint-up(lg) {
        color: $white
    }
}

.activeTab {
    color: $yellow;
    background-color: $dark-red;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    position: relative;
    pointer-events: none;

    &:before,
    &:after {
        content: "";
        position: absolute;
        bottom: 0;
        height: 20px;
        width: 20px;
    }

    &:before {
        left: calc(0% - 20px);
        border-bottom-right-radius: 50%;
        box-shadow: 0 10px 0 0 $dark-red;
    }

    &:after {
        right: calc(0% - 20px);
        border-bottom-left-radius: 50%;
        box-shadow: 0 10px 0 0 $dark-red;
    }
}

.tabContent {
    padding: 16px 32px;
    background-color: $dark-red;
    margin-top: 8px;
}

.menuList {
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: column;
    margin-top: -12px;
    margin-bottom: -12px;

    @include media-breakpoint-up(lg) {
        flex-direction: row;
        flex: 1;
        align-items: center;
        margin: 0;
        padding-left: 50px;
        margin: 0 -20px;
        flex-wrap: wrap;
    }
}

.buttonContainer {
    margin: 24px 0 8px 0;

    @include media-breakpoint-up(lg) {
        display: flex;
        margin: 0;
        min-height: 72px;
    }
}

.linkContainer {
    display: flex;
    flex-direction: column;
    padding: 24px 32px;
    margin-top: -8px;
    margin-bottom: -8px;

    @include media-breakpoint-up(lg) {
        flex-direction: row;
        margin: 0;
        padding: 0;
        flex: 1;
        justify-content: flex-end;
        margin-left: -12px;
        margin-right: -12px;
    }
}

.link {
    padding-top: 8px;
    padding-bottom: 8px;
    font-weight: 600;
    font-size: 14px;
    line-height: 19px;

    a {
        color: rgba($color: $white, $alpha: 0.6);
        padding-bottom: 3px;
    }

    @include media-breakpoint-up(lg) {
        padding: 0 12px;

        a {
            color: $white;
            
            &:hover {
                border-bottom: 2px solid $white;
            }
        }   
    }
}

.linkActive {
    a {
        border-bottom: 2px solid rgba($color: $white, $alpha: 0.6);

        @include media-breakpoint-up(lg) {
            border-bottom: 2px solid $white;
        }
    }
}

.desktopNavigation {
    box-shadow: 0px 15px 30px rgba($color: #8A7366, $alpha: 0.08);
    background: $dark-red;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    height: 104px;
    position: relative;
    display: flex;
    align-items: center;
    padding: 0 16px 0 20px;
    margin-top: 6px;
}

.desktopNavigation,
.mobileNavigation {
    &:after {
        background-image: url('../../assets/images/half-circle-bottom-blue.svg');
        background-position: 0 0;
        background-repeat: repeat-x;
        position: absolute;
        left: 0;
        bottom: -12px;
        width: 100%;
        height: 12px;
        content: '';
        display: block;
    }
}

.tabsDesptop {
    display: flex;
}

.desktopMenu {
    flex: 1;
    display: flex;
}

.mobileButtons {
    display: flex;
    align-items: center;
}