@import '../../../scss/lib/mixins';

.video {
    width: 100%;
    height: auto;
}

.videoPanel {
    border-radius: 16px;
    background-color: $white;
    padding: 12px 12px 32px 12px;
    position: relative;
    margin-bottom: 30px;

    @include media-breakpoint-up(lg) {
        height: 100%;
        display: flex;
        align-items: center;
        margin-bottom: 0;
        padding: 26px 32px 32px 24px;
    }
}

.title {
    font-family: Marujo;
    background: $cyan;
    box-shadow: 0px 15px 10px rgba($color: $black, $alpha: 0.06);
    transform: rotate(-1deg);
    color: $white;
    font-size: 21px;
    line-height: 24px;
    padding: 8px;
    position: absolute;
    text-align: center;
    max-width: 187px;

    @include media-breakpoint-up(lg) {
        font-size: 24px;
        line-height: 27px;
        max-width: 371px;
    }
}