
.rich-text-wrapper {
    > * {
        margin: 24px 0;
    }

    h2,h3,h4,h5,h6 {
        margin-top: 32px;
    }

    /**umb_name:Normal*/
    p {
        font-size: 16px;
        line-height: 22.4px;
    }

    /**umb_name:Quote*/
    q {
    }

    /**umb_name:H6*/
    h6 {
    }

    /**umb_name:H5*/
    h5 {
    }

    /**umb_name:H4*/
    h4 {
    }

    /**umb_name:H3*/
    h3 {
    }

    /**umb_name:H2*/
    h2 {
        display: inline-block;
        transform: rotate(-2deg);
        box-shadow: 0 15px 10px rgba(0, 0, 0, 0.06);
        background-color: #DD4C18;
        color: white;
        padding: 0 10px;
    }

    /**umb_name:Text-Highlight*/
    .text-highlight {
        background-color: #FFFF00;
        padding: 7px;
    }

    p a {
        color: $red;
        text-decoration: underline;
    }

    ol {
        counter-reset: ordered-list;
        padding: 0;
        list-style: none;
        margin: 16px 0;
    }

    ol li { 
        display: flex;
        align-items: center;
        margin-bottom: 8px;

        &:nth-child(3n+1) {
            &::before {
                background-color: #69BE28;
            }
        }
        &:nth-child(3n+2) {
            &::before {
                background-color: #D10074;
            }
        }
        &:nth-child(3n+3) {
            &::before {
                background-color: #522398;
            }
        }
    }

    ol li::before {
        counter-increment: ordered-list;
        content: counter(ordered-list);
        display: inline-flex;
        align-items: center;
        justify-content: center;
        height: 22px;
        width: 22px;
        font-size: 16px;
        font-family: "Marujo";
        color: white;
        border-radius: 100%;
        margin-right: 8px;
        flex-shrink: 0;
    }

    ul {
        list-style: none;
        padding: 0;
        margin: 16px 0;
    }

    ul li {
        align-items: center;
        margin-bottom: 8px;
        vertical-align: baseline;
        a {
            color: $light-pink;
        }
    }

    ul li::before {
        content: url("../assets/images/check.svg");
        display: inline-grid;
        align-items: center;
        justify-content: center;
        height: 22px;
        width: 22px;
        border-radius: 100%;
        background-color: #439ed5;
        margin-right: 8px;
        flex-shrink: 0;
    }

    table {
        display: block;
        overflow-x: auto;
        border-color: transparent;
    }

    tbody {
        border-collapse: collapse;
        box-shadow: 0px 30px 30px rgba(0, 0, 0, 0.05);
    }

    tbody p {
        margin: 0;
    }

    tbody tr {
        background-color: #ffffff;
    }

    tbody tr:not(:last-child) {
        border-bottom: 2px solid #F3F9FE;    
    }

    tbody td {
        border-style: none;
        padding: 24px;
        color: #766165;
    }

    tbody td:first-child {
        font-weight: 700;
    }

    tbody tr:first-child td:first-child {
        border-top-left-radius: 24px;
        border-style: solid;
    }
    tbody tr:first-child td:last-child {
        border-top-right-radius: 24px;
        border-style: solid;
    }

    tbody tr:last-child td:first-child {
        border-bottom-left-radius: 24px;
        border-style: solid;
    }
    tbody tr:last-child td:last-child {
        border-bottom-right-radius: 24px;
        border-style: solid;
    }

    blockquote {
        display: inline-flex;
        margin: 24px 0;
        font-size: 18px;
        font-family: "Marujo";
        line-height: 32px;

        &::before {
            display: block;
            content: open-quote;
            font-size: 56;
            margin-right: 16px;
        }
    }

    img {
        width: 100%;
        height: auto;
        object-fit: cover;
        max-width: 537px;
    }
}